import { waitForAuthorization } from 'core/auth/sagas';
import {
  get,
} from 'lodash';
import {
  put,
  takeLatest,
  select,
  call,
} from 'redux-saga/effects';
import {
  executeQuery, parseError,
} from 'utils/request';

import {
  staffWorkLogsReportActions,
  staffWorkLogsReportTypes,
} from './actions';
import { queries } from './queries';
import { selectEntityName, selectErrors } from './selectors';

function* getStaffWorkLogsReport({ payload }) {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'requiredReviewsReportError', []);
  const entityName = yield select(selectEntityName);
  const errorOptions = {
    entityName,
    storedErrors,
  };
  try {
    const query = queries.getStaffWorkLogs;
    const options = {
      query,
      variables: payload,
    };

    const data = yield call(executeQuery, options, storedErrors);
    yield put(staffWorkLogsReportActions.getStaffWorkLogsSuccess({
      data: get(data, 'devstaffWorklogsReport', null),
    }));
  } catch (error) {
    const staffWorkLogsReportError = yield call(parseError, error, errorOptions);
    yield put(staffWorkLogsReportActions.getStaffWorkLogsFail({
      error: {
        staffWorkLogsReportError,
      },
    }));
  }
}

function* getClients() {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'clients', []);
  const entityName = yield select(selectEntityName);
  const errorOptions = {
    entityName,
    storedErrors,
  };
  try {
    const query = queries.getClients;
    const options = {
      query,
    };
    const data = yield call(executeQuery, options, storedErrors);
    yield put(staffWorkLogsReportActions.getClientsSuccess({
      data: get(data, 'devstaffWorklogsReportClientsList.clients', []),
    }));
  } catch (error) {
    const clientsError = yield call(parseError, error, errorOptions);
    yield put(staffWorkLogsReportActions.getClientsFail({
      error: {
        clients: clientsError,
      },
    }));
  }
}

function* getStaffWorkLogsWatcher() {
  yield takeLatest(staffWorkLogsReportTypes.GET_REPORT, waitForAuthorization(getStaffWorkLogsReport));
}

function* getClientsWatcher() {
  yield takeLatest(staffWorkLogsReportTypes.GET_CLIENTS, waitForAuthorization(getClients));
}
export default [
  getStaffWorkLogsWatcher,
  getClientsWatcher,
];
