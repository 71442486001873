import hash from 'hash-sum';
import { difference, get } from 'lodash';
import { toast } from 'react-toastify';

import { scrollToTop } from './scrolling';

export const mapperErrors = (errorEntity) => typeof errorEntity === 'string' ? errorEntity : errorEntity.toastId;

export const removeNotifications = (storedErrors = []) => {
  storedErrors.forEach((storedError) => toast.dismiss(hash(storedError)));
};

export const generateMessageByCode = (errorMessage, extensions) => {
  if (extensions && extensions.errorCode === 500) {
    return 'Internal Server Error';
  }

  if (extensions && typeof extensions.detailed === 'string' && !extensions.detailed.includes('{')) {
    return extensions.detailed;
  }

  if (errorMessage.includes('1062')) {
    const regex = /Duplicate entry \\'(.+)\\' for key/;
    const match = errorMessage.match(regex);
    return `Category Key "${match[1]}" already exists`;
  }

  return errorMessage;
};

export const getNotifications = ({
  error,
  errorMessage,
  errors = [],
  graphQLErrors = [],
  activeErrors = [],
  containerId,
  isModalError,
}) => {
  if (!isModalError) {
    scrollToTop();
  }

  const errorsForNotifications = [];
  const allErrors = [...errors, ...graphQLErrors];

  if (allErrors.length) {
    allErrors.forEach(({ extensions, message }) => {
      const toastId = hash(message);
      errorsForNotifications.push({
        toastId,
        message,
      });

      if (!toast.isActive(toastId)) {
        toast.dismiss(toastId);
        toast.error(`${message === '' ? 'Something went wrong!' : generateMessageByCode(message, extensions)}`, {
          toastId,
          containerId,
        });
      }
    });

    const fixedErrors = difference(
      activeErrors.map(mapperErrors),
      errorsForNotifications.map(mapperErrors)
    );

    fixedErrors.forEach((errorEntity) => toast.dismiss(typeof errorEntity === 'string' ? errorEntity : errorEntity.toastId));

    return errorsForNotifications;
  }

  const currentErrorMessage = get(error, 'extensions.detailed', null) || errorMessage || 'Something went wrong!';
  const isNetworkError = currentErrorMessage.includes('Network error');
  const toastId = hash(currentErrorMessage);
  if (isNetworkError) {
    toast.dismiss();
    toast.error(currentErrorMessage, { containerId });
  } else if (!toast.isActive(toastId)) {
    toast.dismiss(toastId);
    toast.error(currentErrorMessage, { toastId, containerId });
  }

  return [currentErrorMessage];
};
