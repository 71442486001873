import { createAction } from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from './constants';

export const staffWorkLogsReportTypes = createTypes([
  ...async('GET_REPORT'),
  ...async('GET_CLIENTS'),
  'CHANGE_FILTER',
  'CLEAR_FILTER',
  'CLEAR_DATA',
], ENTITY_NAME);

export const staffWorkLogsReportActions = {
  getClients: createAction(staffWorkLogsReportTypes.GET_CLIENTS),

  getClientsSuccess: createAction(
    staffWorkLogsReportTypes.GET_CLIENTS_SUCCESS,
    ({ data }) => ({ data })
  ),
  getClientsFail: createAction(staffWorkLogsReportTypes.GET_CLIENTS_FAIL, ({
    error = {},
  }) => ({
    error,
  })),

  getStaffWorkLogs: createAction(staffWorkLogsReportTypes.GET_REPORT, (data) => (data)),

  getStaffWorkLogsSuccess: createAction(
    staffWorkLogsReportTypes.GET_REPORT_SUCCESS,
    ({ data }) => ({ data })
  ),
  getStaffWorkLogsFail: createAction(staffWorkLogsReportTypes.GET_REPORT_FAIL, ({
    error = {},
  }) => ({
    error,
  })),

  changeFilter: createAction(
    staffWorkLogsReportTypes.CHANGE_FILTER,
    ({
      storeKey,
      selected,
    }) => ({
      storeKey,
      selected,
    })
  ),

  clearFilter: createAction(staffWorkLogsReportTypes.CLEAR_FILTER),
  clearData: createAction(staffWorkLogsReportTypes.CLEAR_DATA),
};
