import React, { memo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { BasicTable } from 'components/basic-table';
import ExpansionPanel from 'components/expansion-panel';

import styles from './style.module.scss';
import { useReportTable } from './useReportTable';

export const ReportTable = memo(({ label, data, type, totals, isInternal }) => {
  const { columns } = useReportTable(data, type, totals, label, isInternal);
  return (
    <ExpansionPanel
      summaryConfig={{
        groupId: 'staffWorkLog',
        label: (
          <>
            <span>{label}</span>
            <span>{totals.totalHours}h {totals.totalMinutes}m</span>
          </>
        ),
        cssRules: `
          & .expansion-summary__label {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 1rem;

            & span:last-child {
              color: rgba(0, 0, 0, 0.87);
            }
          }

          & .expansion-summary__icon {
            width: 2rem;
            height: 2rem;
          }
        `,
        isDefaultExpanded: false,
      }}
    >
      <BasicTable
        id={label}
        columns={columns}
        className={classNames(styles.table)}
        data={data}
        paginate={{
          enable: false,
        }}
        footerVisibility
        defaultSortDirection="desc"
        isSort
      />
    </ExpansionPanel>
  );
});

ReportTable.propTypes = {
  isInternal: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  totals: PropTypes.object.isRequired,
};
