import { orderBy } from 'lodash';

export const calculateTotalTime = (data) => {
  let totalHours = data.totalHours;
  const totalMinutes = data.totalMinutes;

  const additionalHours = Math.floor(totalMinutes / 60);
  totalHours += additionalHours;

  const remainingMinutes = totalMinutes % 60;

  return {
    totalHours,
    totalMinutes: remainingMinutes.toFixed(1),
  };
};

export const calculateSingleWorklog = (worklog) => {
  const seconds = worklog.time || 0;

  let totalHours = Math.floor(seconds / 3600);
  let totalMinutes = (seconds % 3600) / 60;

  if (totalMinutes >= 60) {
    const additionalHours = Math.floor(totalMinutes / 60);
    totalHours += additionalHours;
    totalMinutes %= 60;
  }

  const roundedMinutes = parseFloat(totalMinutes.toFixed(1));

  return { totalHours, totalMinutes: roundedMinutes };
};

export const calculateTime = (worklogs) => worklogs.reduce(
  (acc, worklog) => {
    worklog.workLogs.forEach((log) => {
      acc.totalHours += log.hoursLogged || 0;
      acc.totalMinutes += log.minutesLogged || 0;
    });

    if (acc.totalMinutes >= 60) {
      acc.totalHours += Math.floor(acc.totalMinutes / 60);
      acc.totalMinutes %= 60;
    }

    acc.totalMinutes = parseFloat(acc.totalMinutes.toFixed(1));

    return acc;
  },
  { totalHours: 0, totalMinutes: 0 }
);

export const transformData = (
  data,
  type
) => {
  if (type === 'Employee') return data;
  const logDateMap = new Map();

  data.forEach((item) => {
    item.worklogs.forEach((worklog) => {
      const logDate = worklog.logDate.split(' ')[0];
      const logDateId = logDate.replace(/-/g, '');

      if (!logDateMap.has(logDate)) {
        logDateMap.set(logDate, {
          logDate,
          logDateId,
          worklogs: [],
        });
      }

      const existingEntry = logDateMap.get(logDate);
      const worklogEntry = existingEntry.worklogs.find(
        (log) => log.resName === item.resName && log.resId === item.resId
      );

      if (worklogEntry) {
        worklogEntry.workLogs = worklogEntry.workLogs.concat(worklog.workLogs);
      } else {
        existingEntry.worklogs.push({
          resName: item.resFullname,
          resId: item.resId,
          clientCategory: worklog.clientCategory,
          clientCategoryId: worklog.clientCategoryId,
          internalCategory: worklog.internalCategory,
          internalCategoryId: worklog.internalCategoryId,
          workLogs: worklog.workLogs,
        });
      }
    });
  });
  return orderBy(
    Array.from(logDateMap.values()),
    [(item) => item.logDate.toLowerCase()],
    ['desc']
  );
};
