import {
  generateSelectItems,
  normalizeRateCardData,
  shouldDisplayRateCardNotificationsData,
} from 'core/configurations/utils';
import { omit } from 'lodash';
import { createSelector } from 'reselect';

export const selectClientRateCardNotifications = (state) => state.configurations.clientRateCardNotifications;

export const selectConfigurationFilters = (state) => state.configurations.filters;

export const selectSendingEmails = (state) => state.configurations.sendingEmailsProgress;

export const selectNotificationsType = createSelector(selectClientRateCardNotifications, (state) => state.notificationsType);

export const selectBillingAgents = createSelector(selectClientRateCardNotifications, (state) => state.billingAgentIds);

export const selectNotificationsStatuses = createSelector(selectClientRateCardNotifications, (state) => state.rateCardNotificationsStatuses);

export const selectRateCardsList = createSelector(selectClientRateCardNotifications, (state) => state.rateCardsForRatecardNotifications);

export const selectNotificationsFilters =
  createSelector(selectConfigurationFilters, (filters) => filters.notifications);

export const selectRateCardNotificationsFilter =
  createSelector(selectNotificationsFilters, (filters) => filters.clientRateCard);

export const selectIsActiveFilters = createSelector(selectRateCardNotificationsFilter, (filters) => Object.values(filters).some((filter) => filter.isActive));

export const selectFormData = createSelector(
  selectClientRateCardNotifications,
  selectRateCardNotificationsFilter,
  selectIsActiveFilters,
  ({ rateCardNotifications }, filterState, hasActiveFilters) => {
    const items = rateCardNotifications.map((item) => {
      const cleanedItem = omit(item, ['accountManagerId', 'version', 'emailDate', 'lineId']);
      cleanedItem.salutation = cleanedItem.salutation || '';
      cleanedItem.processed = Boolean(cleanedItem.processed);
      return cleanedItem;
    });

    if (hasActiveFilters) {
      return items.filter((rateCardNotification) => shouldDisplayRateCardNotificationsData(rateCardNotification, filterState));
    }

    return items;
  }
);

export const selectCounter = createSelector(selectClientRateCardNotifications, selectFormData, ({ rateCardNotifications }, filteredRateCardNotifications) => ({
  total: rateCardNotifications.length,
  selected: filteredRateCardNotifications.length,
  percentage: rateCardNotifications.length ? Math.round((filteredRateCardNotifications.length / rateCardNotifications.length) * 100) : 0,
}));

export const selectRateCardNotifications =
  createSelector(selectClientRateCardNotifications, ({ rateCardNotifications }) => rateCardNotifications);

export const selectBillingAgentIds = createSelector(
  selectBillingAgents,
  (billingAgentIds) => generateSelectItems(billingAgentIds, {
    key: 'billingAgentId',
    valueAccessor: 'billingAgentId',
    labelAccessor: 'shortName',
  })
);

export const selectRateCardsForRatecardNotifications = createSelector(
  selectRateCardsList,
  (rateCardsForRatecardNotifications) => normalizeRateCardData(rateCardsForRatecardNotifications)
);

export const selectNotificationTypes = createSelector(
  selectNotificationsType,
  (notificationsType) => generateSelectItems(notificationsType, {
    key: 'notificationId',
    valueAccessor: 'notificationId',
    labelAccessor: 'notificationTitle',
  })
);

export const selectRateCardNotificationsVersion = createSelector(
  selectClientRateCardNotifications,
  (state) => state.rateCardNotificationsVersion
);

export const selectRateCardNotificationsVersionsList = createSelector(
  selectClientRateCardNotifications,
  ({ rateCardNotificationsVersions }) => {
    if (!rateCardNotificationsVersions) {
      return [];
    }

    return rateCardNotificationsVersions.map((version) => ({
      value: version.versionId,
      label: `Version ${version.versionId}`,
    }));
  }
);

export const selectRateCardNotificationsVersions = createSelector(
  selectClientRateCardNotifications,
  (state) => state.rateCardNotificationsVersions
);

export const selectSendingEmailsProgress = createSelector(selectSendingEmails, (state) => state.notifications.clientRateCard);

export const selectIsSendingEmailsActive = createSelector(selectSendingEmailsProgress, (state) => state.inProgress);
