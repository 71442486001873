import { push } from 'connected-react-router';
import { waitForAuthorization } from 'core/auth/sagas';
import hash from 'hash-sum';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import {
  put,
  takeLatest,
  call,
  select,
  all,
} from 'redux-saga/effects';

import {
  removeEmptyValues,
  getParsedValues,
} from 'utils/helpers/forms';
import {
  executeQuery,
  executeMutation,
  parseError,
} from 'utils/request';

import {
  clientsActionsTypes,
  clientsActions,
} from './actions';
import { queryConfig } from './queries';
import {
  selectErrors,
  selectEntityName,
  selectDevcenters,
  selectExcludedDevcenters,
} from './selectors';
import { parseResponse, parseMutationResponse, getExcludedDevcenters } from './utils';

function* getClientsList() {
  try {
    const query = queryConfig.getClientsList;
    const options = {
      query,
    };
    const {
      clients,
    } = yield call(executeQuery, options);

    return yield put(clientsActions.getClientsListSuccess({
      clientsList: clients || [],
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getClientsListError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getClientsListError = yield call(parseError, error, options);

    return yield put(clientsActions.getClientsListFail({
      error: {
        getClientsListError,
      },
    }));
  }
}

function* getMSAByClientId({
  meta: {
    clientId,
  },
}) {
  try {
    const query = queryConfig.getMsaByClientId(clientId);
    const data = yield call(executeQuery, { query });

    return yield put(clientsActions.getMSAByClientIdSuccess({
      msa: get(data, 'node.msa'),
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getMSAByClientIdError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getClientDetailError = yield call(parseError, error, options);
    return yield put(clientsActions.getMSAByClientIdFail({
      error: {
        getClientDetailError,
      },
    }));
  }
}

function* getClientDetails({
  payload: {
    clientId,
  },
}) {
  try {
    const query = queryConfig.getClientDetails(clientId);
    const options = {
      query,
    };

    const data = yield call(executeQuery, options);
    const clientDetails = parseResponse(data);
    return yield put(clientsActions.getClientDetailSuccess({
      clientDetails,
    }));
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'getClientDetailError', []);
    const options = {
      entityName,
      storedErrors,
    };
    const getClientDetailError = yield call(parseError, error, options);
    return yield put(clientsActions.getClientDetailFail({
      error: {
        getClientDetailError,
      },
    }));
  }
}

function* createClient({
  payload: {
    fields,
    initialValues,
  },
}) {
  try {
    const allDevcenters = yield select(selectDevcenters);
    const mutation = queryConfig.createClient;
    const currentExcludedDevcenters = get(fields, 'excludedDevcenters', []);
    const { excludedDevcenters, ...parsedValues } = removeEmptyValues(fields, initialValues);
    const isEmptyExcludedDevcenters = isEmpty(excludedDevcenters);

    const variables = {
      fields: {
        ...parsedValues,
        ...!isEmptyExcludedDevcenters && {
          excludedDevcenters:
            {
              devcenterIds: getExcludedDevcenters(allDevcenters, excludedDevcenters),
              isEmpty: isEmptyExcludedDevcenters,
            },
        },
        ...!currentExcludedDevcenters.length && {
          excludedDevcenters:
            {
              isEmpty: true,
            },
        },
      },
    };
    const options = {
      mutation,
      variables,
    };
    const data = yield call(executeMutation, options);
    const node = get(data, 'createClient.client', {});
    const defaultMsaTemplate = get(data, 'createClient.defaultMsaTemplate', {});
    const clientId = get(node, 'clientId');
    const clientDetails = parseResponse({ node, defaultMsaTemplate });

    return yield all([
      put(clientsActions.createClientSuccess({
        clientDetails,
      })),
      put(push({
        pathname: `${clientId}/billing-info`,
      })),
    ]);
  } catch (error) {
    const errors = yield select(selectErrors);
    const entityName = yield select(selectEntityName);
    const storedErrors = get(errors, 'createClientError', []);
    storedErrors.forEach((stError) => {
      const currentToastId = hash(stError);
      toast.dismiss(currentToastId);
    });

    const options = {
      entityName,
      storedErrors,
      errorMessage: get(error, 'extensions.detailed', null),
    };
    const createClientError = yield call(parseError, error, options);

    return yield put(clientsActions.createClientFail({
      error: {
        createClientError,
      },
    }));
  }
}

function* updateClientDetails({
  payload: {
    clientId,
    fields,
    initialValues,
  },
}) {
  try {
    const allDevcenters = yield select(selectDevcenters);
    const initialExcludedDevcentersSelect = yield select(selectExcludedDevcenters);
    const initialExcludedDevcenters = get(initialExcludedDevcentersSelect, '', []);
    const currentExcludedDevcenters = get(fields, 'excludedDevcenters', []);
    const mutation = queryConfig.updateClientDetails(clientId);
    const { excludedDevcenters, ...parsedValues } = getParsedValues(fields, initialValues);
    const isEmptyExcludedDevcenters = isEmpty(excludedDevcenters);
    const notEqualInitialValues = initialExcludedDevcenters.length !== currentExcludedDevcenters.length;
    const variables = {
      fields: {
        ...parsedValues,
        ...(excludedDevcenters && (!isEmptyExcludedDevcenters || notEqualInitialValues)) && {
          excludedDevcenters: {
            devcenterIds: getExcludedDevcenters(allDevcenters, excludedDevcenters),
            isEmpty: isEmptyExcludedDevcenters,
          },
        },
        ...(excludedDevcenters && (isEmptyExcludedDevcenters && !notEqualInitialValues) && {
          excludedDevcenters: {
            devcenterIds: [],
            isEmpty: true,
          },
        }),
      },
    };

    const options = {
      mutation,
      variables,
    };

    const data = yield call(executeMutation, options);
    const clientDetails = parseMutationResponse(data);

    return yield all([
      put(clientsActions.updateClientDetailsSuccess({
        withModal: true,
        clientId,
        fields: parsedValues,
        clientDetails,
      })),
    ]);
  } catch (error) {
    const entityName = yield select(selectEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'updateClientDetailsError', []);
    const options = {
      entityName,
      storedErrors,
    };

    const updateClientDetailsError = yield parseError(error, options);

    return yield put(clientsActions.updateClientDetailsFail({
      error: {
        updateClientDetailsError,
      },
    }));
  }
}

function* getClientsListWatcher() {
  yield takeLatest(clientsActionsTypes.GET_CLIENTS_LIST, waitForAuthorization(getClientsList));
}

function* getClientDetailWatcher() {
  yield takeLatest(clientsActionsTypes.GET_CLIENT_DETAILS, waitForAuthorization(getClientDetails));
}

function* createClientWatcher() {
  yield takeLatest(clientsActionsTypes.CREATE_CLIENT, waitForAuthorization(createClient));
}

function* updateClientDetailsWatcher() {
  yield takeLatest(clientsActionsTypes.UPDATE_CLIENT_DETAILS, waitForAuthorization(updateClientDetails));
}

function* getMsaByClientIdWatcher() {
  yield takeLatest([
    clientsActionsTypes.GET_UPLOADED_FILE_SUCCESS,
    clientsActionsTypes.DELETE_DOCUMENT_SUCCESS,
  ], waitForAuthorization(getMSAByClientId));
}

export default [
  createClientWatcher,
  getClientsListWatcher,
  getClientDetailWatcher,
  updateClientDetailsWatcher,
  getMsaByClientIdWatcher,
];
