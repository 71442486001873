import React, { memo } from 'react';

import PropTypes from 'prop-types';

import Filter from 'components/filter';

import { useFilter } from './useFilter';

export const ReportFilter = memo(({ isInternal, internalCategories, clientCategories, selected, onChange }) => {
  const { css, filterConfig } = useFilter({ isInternal, internalCategories, selected, clientCategories });
  return (
    <Filter
      cssRules={css}
      filterConfig={filterConfig}
      changeFilter={onChange}
    />
  );
});

ReportFilter.propTypes = {
  isInternal: PropTypes.bool.isRequired,
  internalCategories: PropTypes.array.isRequired,
  clientCategories: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
};
