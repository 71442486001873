import { get, set } from 'lodash';

import getUuid from '../helpers/uuid';

let instance;
class SingletonClass {
  static groups = {};

  static controls = {};

  static remoteControllers = {};

  static groupSubscribers = {};

  constructor() {
    if (typeof instance === 'object') {
      throw new Error('Only one instance can exist!');
    }
    instance = this;
    // eslint-disable-next-line no-constructor-return
    return this;
  }

  setGroupState({ state, groupId }) {
    const group = get(SingletonClass.groups, groupId, {});
    const groupValues = Object.entries(group);

    groupValues.forEach(([id, data]) => {
      set(SingletonClass, `groups.${groupId}.${id}.state`, state);
      if (get(data, 'setIsExpanded', false)) data.setIsExpanded(state);
    });

    this.notifyGroupSubscribers(groupId);
  }

  updateControlState({ id, state, groupId }) {
    set(SingletonClass, `groups.${groupId}.${id}.state`, state);
    this.checkControls({ groupId });
    this.notifyGroupSubscribers(groupId);
  }

  checkControls({ groupId }) {
    const group = get(SingletonClass.groups, groupId, {});
    const remoteControls = get(SingletonClass, `remoteControllers.${groupId}`, {});
    const isALLClosed = !Object.values(group).some(({ state, isDisabled }) => state && !isDisabled);
    const isALLOpened = !Object.values(group).some(({ state, isDisabled }) => !state && !isDisabled);

    if (isALLClosed) Object.values(remoteControls).forEach((func) => func(false));
    if (isALLOpened) Object.values(remoteControls).forEach((func) => func(true));

    this.notifyGroupSubscribers(groupId);
  }

  getGroupState({ groupId }) {
    const group = get(SingletonClass.groups, groupId, {});
    const groupValues = Object.values(group);
    const isALLOpened = !groupValues.some(({ state, isDisabled }) => !state && !isDisabled);
    const isALLClosed = !groupValues.some(({ state, isDisabled }) => state && !isDisabled);

    return { isALLOpened, isALLClosed };
  }

  subscribe({ state, groupId, isDisabled, setIsExpanded }) {
    const id = getUuid();
    set(SingletonClass, `groups.${groupId}.${id}`, { state, isDisabled, setIsExpanded });

    return {
      id,
      unsubscribe: () => this.unsubscribe({ groupId, id }),
    };
  }

  unsubscribe({ groupId, id }) {
    delete SingletonClass.groups[groupId][id];
    this.notifyGroupSubscribers(groupId);
  }

  subscribeRemoteController({ groupId, setIsExpanded }) {
    const id = getUuid();
    set(SingletonClass, `remoteControllers.${groupId}.${id}`, setIsExpanded);

    return () => {
      this.unsubscribeRemoteController({ groupId, id });
    };
  }

  unsubscribeRemoteController({ groupId, id }) {
    delete SingletonClass.remoteControllers[groupId][id];
  }

  updateCallback({ id, groupId, isDisabled, setIsExpanded }) {
    set(SingletonClass, `groups.${groupId}.${id}.isDisabled`, isDisabled);
    set(SingletonClass, `groups.${groupId}.${id}.setIsExpanded`, setIsExpanded);
  }

  subscribeToGroup(groupId, callback) {
    if (!SingletonClass.groupSubscribers[groupId]) {
      SingletonClass.groupSubscribers[groupId] = new Set();
    }
    SingletonClass.groupSubscribers[groupId].add(callback);

    return () => this.unsubscribeFromGroup(groupId, callback);
  }

  unsubscribeFromGroup(groupId, callback) {
    if (SingletonClass.groupSubscribers[groupId]) {
      SingletonClass.groupSubscribers[groupId].delete(callback);
    }
  }

  notifyGroupSubscribers(groupId) {
    const groupState = this.getGroupState({ groupId });
    if (SingletonClass.groupSubscribers[groupId]) {
      SingletonClass.groupSubscribers[groupId].forEach((callback) => callback(groupState));
    }
  }
}

export default new SingletonClass();
