import React, { memo, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { BasicTable } from 'components/basic-table';
import ExpansionPanel from 'components/expansion-panel';
import ExpandingSectionStore from 'utils/expanding-sections-store';

import styles from './style.module.scss';
import { useReportTable } from './useReportTable';

export const ReportTable = memo(({ label, data }) => {
  const [groupState, setGroupState] = useState(ExpandingSectionStore.getGroupState({ groupId: 'requiredReviews' }));
  const [collapseReviews, setCollapseReviews] = useState([]);

  const toggleCollapse = (id) => {
    if (collapseReviews.includes(id)) {
      return setCollapseReviews((prev) => prev.filter((item) => item !== id));
    }
    return setCollapseReviews((prev) => [...prev, id]);
  };

  const { columns } = useReportTable(data, collapseReviews, toggleCollapse);

  useEffect(() => {
    const unsubscribe = ExpandingSectionStore.subscribeToGroup('requiredReviews', setGroupState);

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (groupState.isALLClosed) {
      setCollapseReviews(() => []);
    }
  }, [groupState]);

  return (
    <ExpansionPanel
      summaryConfig={{
        groupId: 'requiredReviews',
        label,
        isDefaultExpanded: true,
      }}
    >
      <BasicTable
        id={label}
        columns={columns}
        className={classNames(styles.table)}
        data={data}
        paginate={{
          enable: false,
        }}
        isSort
      />
    </ExpansionPanel>
  );
});

ReportTable.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
