/* eslint-disable no-useless-escape */
import {
  rateCardInputFieldCSS,
  billingInfoTabFormViewboxCss,
} from 'assets/styles/config';
import {
  colorSecondaryText,
  colorSecondaryGrayLight,
} from 'assets/styles/variables';
import {
  changeAccountManagerExpirationDate,
  updateOvertimeSurcharge,
  changeDateFirstInvoice,
  changeBillingAgentLang,
  changeConnectedClient,
  changeAccountManager,
  updateClientDetails,
  updatePaymentTerms,
  changeBillingAgent,
  toggleIsPORequired,
  changeOwner,
} from 'core/auth/guaranteedAccessRoles';
import {
  rateCardsParentFieldValueGetter,
} from 'layouts/client-details/utils';
import {
  isMatchWith,
  toNumber,
  get,
} from 'lodash';
import moment from 'moment';

import {
  getHasPermissions,
} from 'utils/auth';

import { emailRegex } from 'utils/email';
import {
  stringCompareFunction,
} from 'utils/helpers/sorting';

import {
  array,
  number,
  object,
  string,
} from 'yup';

export default ({
  isHRM,
  isDOA,
  userGroup,
  accountId,
  currentTemplateRatecard,
  isCreatingForm,
  clientRatecardRates,
  ratecards: currentRateCards,
  ratecardId: currentRatecardId,
  devcenters,
}) => {
  const currentRateCard = currentRateCards.find(({
    ratecardId,
  }) => ratecardId === currentRatecardId) || {};

  const ratecards = [];

  currentRateCards.forEach(({
    ratecardId,
    name,
    billingAgentId,
    rates,
  }) => {
    ratecards.push({
      value: ratecardId,
      label: name,
      billingAgentId,
      rates,
    });
  });

  if (currentRatecardId) {
    ratecards.push({
      value: -currentRatecardId,
      label: currentRateCard.name,
      billingAgentId: currentRateCard.billingAgentId,
      rates: clientRatecardRates,
    });
  }

  const ratecardsForSelect = ratecards.sort(({
    label: labelA = '',
  }, {
    label: labelB = '',
  }) => {
    const left = labelA.match(/20\d\d/, '');
    const right = labelB.match(/20\d\d/, '');

    return right - left;
  });

  return isHRM || isDOA ? [] : [{
    type: 'form',
    stylesTemplate: {
      cssRules: `
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: "billing account account management" "rateSelect rateCard rateCard rateCard";
      `,
      formStyles: `
        .Toastify { margin: 2rem 1.6rem 0; }
      `,
      formControlsCss: `
        grid-template-columns: repeat(4, calc((136rem - 4.8rem * 3 - 3.2rem) / 4));
        .button { margin-top: 0; }
      `,
    },
    withoutFormControls: !getHasPermissions(userGroup, updateClientDetails),
    content: [{
      title: 'Billing Info',
      stylesTemplate: {
        gridArea: 'billing',
        marginConfig: 'n',
        cssRules: `
          grid-template-columns: minmax(0, 1fr);
       `,
      },
      formData: [{
        type: 'select',
        name: 'billingAgentId',
        placeholder: 'Billing Agent',
        pathInAPIResponse: ['billingAgent', 'billingAgentId'],
        selectItems: 'billingAgents',
        getIsUnitLocked: () => !isCreatingForm && !getHasPermissions(userGroup, changeBillingAgent),
        valueGetter: ({
          billingAgentId: currentBillingAgentId,
          billingAgents = [],
        }) => {
          const currentItem = billingAgents.find((item) => item.billingAgentId === currentBillingAgentId);

          return {
            selected: {
              value: currentItem ? currentItem.billingAgentId : '',
              label: currentItem ? currentItem.name : '',
            },
            items: billingAgents.map(({
              name,
              billingAgentId,
            }) => ({
              value: billingAgentId,
              label: name,
            })),
          };
        },
        validationRules: {
          isRequired: true,
          schemaGetter: () => number().required('Required field'),
        },
      },
      {
        type: 'select',
        name: 'billingLang',
        placeholder: 'Reporting Language',
        pathInAPIResponse: ['billingLang'],
        selectItems: 'languages',
        getIsUnitLocked: () => !isCreatingForm && !getHasPermissions(userGroup, changeBillingAgentLang),
        valueGetter: ({
          billingLang,
          languages = [],
        }) => {
          const currentItem = languages.find((item) => item.code === billingLang);

          return {
            selected: {
              value: currentItem ? currentItem.code : '',
              label: currentItem ? currentItem.name : '',
            },
            items: languages.map(({
              name,
              code,
            }) => ({
              value: code,
              label: name,
            })),
          };
        },
        validationRules: {
          isRequired: true,
          schemaGetter: () => string().required('Required field'),
        },
      },
      {
        type: 'select',
        name: 'paymentDays',
        placeholder: 'Payment Terms',
        selectItems: 'paymentTerms',
        valueGetter: ({
          paymentDays,
          paymentTerms = [],
        }) => {
          const currentItem = paymentTerms.find((item) => item.paymentTermId === paymentDays);

          return {
            selected: {
              value: currentItem ? currentItem.paymentTermId : paymentDays,
              label: currentItem ? currentItem.description : '',
            },
            items: paymentTerms.sort((a, b) => a.days - b.days).map(({
              description,
              paymentTermId,
            }) => ({
              value: paymentTermId,
              label: description,
            })),
          };
        },
        getIsUnitLocked: () => !isCreatingForm && !getHasPermissions(userGroup, updatePaymentTerms),
        validationRules: {
          isRequired: true,
          schemaGetter: () => number().min(1, 'Required field').required('Required field'),
        },
      },
      {
        type: 'text',
        withLabel: true,
        name: 'overtimePerc',
        placeholder: 'Overtime Surcharge, %',
        valueGetter: ({
          overtimePerc,
        }) => ({
          value: overtimePerc || '',
        }),
        getIsUnitLocked: () => !isCreatingForm && !getHasPermissions(userGroup, updateOvertimeSurcharge),
        validationRules: {
          isRequired: true,
          isNumeric: true,
          minValue: 1,
          maxValue: 99,
          customValidation: (value) => {
            if (!value) {
              return value === undefined;
            }
            const valueToNumber = toNumber(value);

            return valueToNumber >= 1 && valueToNumber <= 99;
          },
          schemaGetter: () => string()
            .nullable()
            .min(1, 'Overtime Surcharge can\'t be below or equal to 1')
            .max(99, 'Overtime Surcharge max value is 99')
            .required('Required field (values 1 - 99)'),
        },
      },
      ],
    },
    {
      title: 'Accounting Info',
      stylesTemplate: {
        gridArea: 'account',
        marginConfig: '2n',
        cssRules: `
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        `,
      },
      formData: [{
        type: 'text',
        name: 'pocAccountName',
        placeholder: 'Accounting Contact',
        withLabel: true,
        pathInAPIResponse: ['pocAccountName'],
        valueGetter: ({
          pocAccountName,
        }) => ({
          value: pocAccountName || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        validationRules: {
          maxLength: 50,
          schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Accounting Contact max length is 50'),
        },
      },

      {
        type: 'text',
        name: 'pocAccountEmail',
        withLabel: true,
        placeholder: 'Email',
        pathInAPIResponse: ['pocAccountEmail'],
        valueGetter: ({
          pocAccountEmail,
        }) => ({
          value: pocAccountEmail || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        validationRules: {
          maxLength: 50,
          schemaGetter: () => (
            string()
              .max(50, 'Email max length is 50')
              .test('isAllEmails', 'Invalid email address', (value) => {
                if (!value) {
                  return true;
                }
                return value.trim().match(emailRegex);
              })
          ),
        },
      },

      {
        type: 'text',
        name: 'pocAccountPhone',
        withLabel: true,
        placeholder: 'Office Phone',
        pathInAPIResponse: ['pocAccountPhone'],
        valueGetter: ({
          pocAccountPhone,
        }) => ({
          value: pocAccountPhone || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        validationRules: {
          maxLength: 50,
          schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Office Phone max length is 50'),
        },
      },
      {
        type: 'toggle',
        name: 'poRequired',
        placeholder: 'PO Required',
        valueGetter: ({
          poRequired,
        }) => !!poRequired,
        validationRules: {},
        getIsUnitLocked: () => !getHasPermissions(userGroup, toggleIsPORequired),
      },
      {
        type: 'text',
        name: 'pocAccountFax',
        withLabel: true,
        placeholder: 'Fax',
        valueGetter: ({
          pocAccountFax,
        }) => ({
          value: pocAccountFax || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        validationRules: {
          maxLength: 30,
          schemaGetter: () => string().transform((value) => value.trim()).max(30, 'Fax max length is 30'),
        },
      },
      {
        type: 'text',
        name: 'pocAccountCell',
        withLabel: true,
        placeholder: 'Cell Phone',
        pathInAPIResponse: ['pocAccountCell'],
        valueGetter: ({
          pocAccountCell,
        }) => ({
          value: pocAccountCell || '',
        }),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),
        validationRules: {
          maxLength: 50,
          schemaGetter: () => string().transform((value) => value.trim()).max(50, 'Cell Phone max length is 50'),
        },
      },
      {
        type: 'select',
        name: 'connectedClient',
        placeholder: 'Connected Client',
        getIsUnitLocked: () => !isCreatingForm && !getHasPermissions(userGroup, changeConnectedClient),
        valueGetter: ({
          connectedClient,
          clients = [],
        }) => {
          const currentItem = clients.find((item) => +item.clientId === +connectedClient);
          return {
            selected: {
              value: currentItem ? currentItem.clientId : connectedClient,
              label: currentItem ? currentItem.name : '',
            },

            items: [{
              value: '*',
              label: '---',
            }, ...clients.sort((a, b) => stringCompareFunction(a.name, b.name)).map(({
              name,
              clientId,
            }) => ({
              value: clientId,
              label: name,
            }))],
          };
        },
        validationRules: { },
      },
      ],
    },

    {
      title: 'Account Management',
      stylesTemplate: {
        gridArea: 'management',
        cssRules: `
          grid-template-columns: minmax(0, 1fr);
        `,
      },
      formData: [{
        type: 'select',
        name: 'ownerId',
        placeholder: 'HiQo Owner',
        getIsUnitLocked: () => !getHasPermissions(userGroup, changeOwner),
        valueGetter: ({
          ownerId,
          users = [],
        }) => {
          const filteredItems = users.filter(({
            isSales,
          }) => isSales);
          const currentItem = users.find((item) => ownerId ?
            item.userId === ownerId :
            item.userId === accountId);
          const isCurrentUserSales = get(currentItem, 'isSales', true);
          if (!isCurrentUserSales && !isCreatingForm) {
            filteredItems.push(currentItem);
          }
          const value = isCurrentUserSales ? get(currentItem, 'userId', 2) : 2;

          return {
            selected: {
              value,
            },
            items: filteredItems.map(({
              userId,
              fullName,
            }) => ({
              value: userId,
              label: fullName,
            })),
          };
        },
        validationRules: {},
      },

      {
        type: 'select',
        name: 'accountManagerId',
        placeholder: 'HiQo AM',
        pathInAPIResponse: ['accountManagerId'],
        selectItems: 'users',
        getIsUnitLocked: () => !getHasPermissions(userGroup, changeAccountManager),
        valueGetter: ({
          accountManagerId,
          users = [],
        }) => {
          const filteredItems = users.filter(({
            isSales,
          }) => isSales);
          const currentItem = users.find((item) => accountManagerId ?
            item.userId === accountManagerId :
            item.userId === accountId);
          const isCurrentUserSales = get(currentItem, 'isSales', true);
          if (!isCurrentUserSales && !isCreatingForm) {
            filteredItems.push(currentItem);
          }
          const value = isCurrentUserSales ? get(currentItem, 'userId', 2) : 2;

          return {
            selected: {
              value,
            },
            items: filteredItems.map(({
              userId,
              fullName,
            }) => ({
              value: userId,
              label: fullName,
            })),
          };
        },
        validationRules: {},
      },
      {
        type: 'select',
        name: 'channelPartnerId',
        placeholder: 'Channel Partner',
        pathInAPIResponse: ['channelPartnerId'],
        selectItems: 'channelPartners',
        getIsUnitLocked: () => !getHasPermissions(userGroup, changeAccountManager),
        valueGetter: ({ channelPartnerId, channelPartners = [] }) => {
          const channelPartnersDataForSelect = channelPartners.map((channelPartner) => ({
            value: channelPartner.channelPartnerId,
            label: channelPartner.name,
          }));
          return ({
            selected: {
              value: channelPartnerId,
            },
            items: [{
              value: '*',
              label: '---',
            }, ...channelPartnersDataForSelect],
          });
        },
        validationRules: {},
      },
      {
        type: 'calendar',
        name: 'firstInvoice',
        placeholder: 'First Invoice',
        valueGetter: ({ firstInvoice }) => firstInvoice || null,
        outputFormatting: ({ date }) => (
          moment.isMoment(date) ?
            date.format('YYYY-MM-DD') :
            date
        ),
        getIsUnitLocked: () => !getHasPermissions(userGroup, changeDateFirstInvoice),
        validationRules: {
          schemaGetter: () => string().transform((value) => value || null).nullable(),
        },
        cssRules: `
          align-items: center;
          max-height: unset;
          font-size: 1.5rem;
          color: ${colorSecondaryText};
        `,
      }, {
        type: 'calendar',
        name: 'accountManagerExpDate',
        placeholder: 'AM commission through',
        valueGetter: ({ accountManagerExpDate }) => accountManagerExpDate || null,
        outputFormatting: ({ date }) => (
          moment.isMoment(date) ?
            date.format('YYYY-MM-DD') :
            date
        ),
        getIsUnitLocked: () => !getHasPermissions(userGroup, changeAccountManagerExpirationDate),
        validationRules: {
          schemaGetter: () => string().transform((value) => value || null).nullable(),
        },
        cssRules: `
          align-items: center;
          max-height: unset;
          font-size: 1.5rem;
          color: ${colorSecondaryText};
        `,
      }],
    },

    {
      title: 'Client Rate Card',
      stylesTemplate: {
        gridArea: 'rateSelect',
        gridRowGap: !getHasPermissions(userGroup, updateClientDetails) ? '0' : '0.2rem',
        marginTop: !getHasPermissions(userGroup, updateClientDetails) ? '0' : '1.9rem',
        cssRules: `
          grid-template-columns: minmax(0, 1fr);
        `,
      },
      formData: [{
        type: 'tableHeading',
        isHidden: getHasPermissions(userGroup, updateClientDetails),

        value: {
          title: 'Rate Card Info',
          cssRules: `
            border-bottom: 1px solid transparent;
          `,
        },
      },

      {
        type: 'viewBox',
        isHidden: getHasPermissions(userGroup, updateClientDetails),
        getIsUnitLocked: () => true,
        valueGetter: () => ({
          value: currentRateCard.name || '',
        }),

        validationRules: {},
        cssRules: `
          min-height: 4.5rem;
          max-height: 4.5rem;
        `,
      },
      {
        type: 'select',
        name: 'ratecardId',
        placeholder: 'Rate card',

        isHidden: !getHasPermissions(userGroup, updateClientDetails),
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),

        valueGetter: ({
          ratecardId: key,
          billingAgentId: currentBillingAgentId,
        }) => {
          const selected = {
            billingAgentId: currentRateCard.billingAgentId,
            value: -currentRateCard.ratecardId || key,
            label: currentRateCard.name || '',
            rates: currentRateCard.rates || [],
          };

          return {
            selected,
            items: ratecardsForSelect.filter(({
              billingAgentId,
            }) => billingAgentId === currentBillingAgentId),
            hideSelectedOptions: true,
            optionsFilter: ({
              value,
            }) => value >= 0,
          };
        },

        parentField: {
          name: ['clientRatecardRates', 'billingAgentId'],

          valueGetter: (data, fieldData) => {
            const {
              billingAgentId,
              ratecardId,
            } = data;
            const {
              selected,
            } = fieldData;

            const billingAgentRateCards = ratecardsForSelect.filter(({
              billingAgentId: itemBillingAgentId,
            }) => billingAgentId === itemBillingAgentId);
            const ratecard = ratecardsForSelect.find((item) => item.value === ratecardId);

            return {
              ...fieldData,
              selected: ratecard || selected,
              items: billingAgentRateCards,
            };
          },
        },

        validationRules: {
          isRequired: true,
          schemaGetter: () => number().transform((value) => Math.abs(value)).min(1, 'Required field').required('Required field'),
        },
      },

      {
        type: 'viewBox',
        getIsUnitLocked: () => !getHasPermissions(userGroup, updateClientDetails),

        valueGetter: () => ({
          withIcon: true,
        }),

        parentField: {
          name: ['clientRatecardRates'],
          strictDependence: true,

          valueGetter: ({
            clientRatecardRates: formclientRatecardRates,
            ratecardId,
          }) => {
            if (ratecardId && currentTemplateRatecard) {
              const {
                rates,
                ratecardId: templateRatecardId,
              } = currentTemplateRatecard;
              const {
                rates: formRates,
              } = templateRatecardId ? {} : currentRateCards.find(({
                ratecardId: id,
              }) => id === ratecardId) || {};
              const ratecard = templateRatecardId ? rates : formRates;

              const matchRates = (formClientRates, rateCardRates) => {
                if (formClientRates && rateCardRates) {
                  if (formClientRates.length !== rateCardRates.length) {
                    return true;
                  }

                  for (let i = 0; i <= formClientRates.length; i++) {
                    const match = isMatchWith(formClientRates[i], rateCardRates[i], (objValue, srcValue) => Number.isInteger(objValue) ? Number(objValue) === Number(srcValue) : objValue === srcValue);

                    if (!match) {
                      return true;
                    }
                  }
                }
                return false;
              };

              const hasRatesChanges = matchRates(formclientRatecardRates, ratecard);
              const message = hasRatesChanges ? 'Rates are customized' : 'Default Rates';

              return ({
                value: message,
                isActive: hasRatesChanges,
                isDefault: !hasRatesChanges,
              });
            }

            return ({
              value: 'Rate card not saved',
              isActive: true,
            });
          },

        },

        validationRules: {},

        cssRules: `
                font-size: 1.4rem;
                line-height: 1.8rem;
                min-height: 4.5rem;
                max-height: 4.5rem;
              `,
      },

      {
        type: 'toggle',
        name: 'isPlatinumRates',
        placeholder: 'Eligible for Platinum Rates',
        isHidden: !getHasPermissions(userGroup, updateClientDetails),
        valueGetter: ({
          isPlatinumRates,
        }) => isCreatingForm || !!isPlatinumRates,
        getIsUnitLocked: ({
          inArrears,
        }) => !getHasPermissions(userGroup, updateClientDetails) || inArrears,
        validationRules: {},
        cssRules: `
          border-bottom: 1px solid ${colorSecondaryGrayLight};
          min-height: 4.5rem;
        `,
      },

      {
        type: 'viewBox',
        getIsUnitLocked: () => true,
        isHidden: getHasPermissions(userGroup, updateClientDetails),

        valueGetter: ({
          isPlatinumRates,
        }) => ({
          value: isPlatinumRates ? 'Platinum' : 'Not Platinum',
          isDefault: !isPlatinumRates,
        }),

        cssRules: `
                min-height: 4.5rem;
                max-height: 4.5rem;
              `,

      },
      ],
    },
    {
      stylesTemplate: {
        gridColumnGap: '1.6rem',
        gridRowGap: !getHasPermissions(userGroup, updateClientDetails) ? '0' : '0.2rem',
        marginTop: !getHasPermissions(userGroup, updateClientDetails) ? '2.4rem' : '4.4rem',
        gridArea: 'rateCard',
        cssRules: '',
      },
      formData: [{
        isFieldArray: true,
        name: 'clientRatecardRates',
        parentField: {
          name: ['ratecardId'],
          valueGetter: ({ ratecardId }) => {
            const selectedRatecard = currentRateCards.find((rate) => rate.ratecardId === ratecardId);
            return selectedRatecard ? selectedRatecard.rates : [];
          },
          valueSetter: (setFieldValue, selectedClientRatecardRates) => {
            if (selectedClientRatecardRates) {
              setFieldValue('clientRatecardRates', selectedClientRatecardRates);
            }
          },
        },
        valueGetter: ({
          clientRatecardRates: clientRates,
          templateRatecard,
          ratecards: seniorityRates = [],
        }) => {
          const vendorById = {};
          if (devcenters) {
            devcenters.forEach((devcenter) => {
              vendorById[devcenter.value] = devcenter.label;
            });
          }
          return {
            title: 'Rate Card',
            value: clientRates,
            ratecardId: templateRatecard ? templateRatecard.ratecardId : null,
            lineGetter: ({
              index,
              lineValues,
            }) => {
              const {
                seniority,
                devcenter,
                ratePtm,
                rateHr,
                rateDisc,
                ratePlatinum,
                rateMo,
                rateMoPlatinum,
              } = lineValues || {};
              return {
                lineId: `line_${index}`,
                line: [{
                  type: 'viewBox',
                  name: `clientRatecardRates[${index}].seniority`,
                  fieldData: {
                    value: seniority,
                    seniorityRates,
                    ratesIndex: index,
                    name: 'seniority',
                  },
                  parentField: {
                    name: ['ratecardId'],
                    valueGetter: rateCardsParentFieldValueGetter,
                  },
                  cssRules: `
                  ${billingInfoTabFormViewboxCss}
                  text-transform: uppercase;`,
                },
                {
                  type: 'viewBox',
                  name: `clientRatecardRates[${index}].devcenter`,
                  fieldData: {
                    value: vendorById[devcenter],
                    seniorityRates,
                    ratesIndex: index,
                    name: 'devcenter',
                    devcenters: vendorById,
                  },
                  parentField: {
                    name: ['ratecardId'],
                    valueGetter: rateCardsParentFieldValueGetter,
                  },
                  cssRules: `
                  ${billingInfoTabFormViewboxCss}
                  `,
                },
                {
                  type: 'text',
                  name: `clientRatecardRates[${index}].ratePtm`,
                  label: 'ratePtm',
                  fieldData: {
                    currentRateCardRate: currentTemplateRatecard && currentTemplateRatecard.rates[index] ? currentTemplateRatecard.rates[index].ratePtm : '',
                    placeholder: ratePtm,
                    seniorityRates,
                    ratesIndex: index,
                    name: 'ratePtm',
                  },
                  parentField: {
                    name: ['ratecardId'],
                    valueGetter: rateCardsParentFieldValueGetter,
                  },
                  validationRules: {
                    isRates: true,
                    isNumeric: true,
                    maxValue: 99999.99,
                  },
                  cssRules: rateCardInputFieldCSS,
                },
                {
                  type: 'text',
                  name: `clientRatecardRates[${index}].rateHr`,
                  label: 'rateHr',
                  fieldData: {
                    currentRateCardRate: currentTemplateRatecard && currentTemplateRatecard.rates[index] ? currentTemplateRatecard.rates[index].rateHr : '',
                    placeholder: rateHr,
                    seniorityRates,
                    ratesIndex: index,
                    name: 'rateHr',
                  },
                  parentField: {
                    name: ['ratecardId'],
                    valueGetter: rateCardsParentFieldValueGetter,
                  },
                  validationRules: {
                    isRates: true,
                    isNumeric: true,
                    maxValue: 99999.99,
                  },
                  cssRules: rateCardInputFieldCSS,
                },
                {
                  type: 'text',
                  name: `clientRatecardRates[${index}].rateDisc`,
                  label: 'rateDisc',
                  fieldData: {
                    currentRateCardRate: currentTemplateRatecard && currentTemplateRatecard.rates[index] ? currentTemplateRatecard.rates[index].rateDisc : '',
                    placeholder: rateDisc,
                    seniorityRates,
                    ratesIndex: index,
                    name: 'rateDisc',
                  },
                  parentField: {
                    name: ['ratecardId'],
                    valueGetter: rateCardsParentFieldValueGetter,
                  },
                  validationRules: {
                    isRates: true,
                    isNumeric: true,
                    maxValue: 99999.99,
                  },
                  cssRules: rateCardInputFieldCSS,
                },
                {
                  type: 'text',
                  name: `clientRatecardRates[${index}].ratePlatinum`,
                  label: 'ratePlatinum',
                  fieldData: {
                    currentRateCardRate: currentTemplateRatecard && currentTemplateRatecard.rates[index] ? currentTemplateRatecard.rates[index].ratePlatinum : '',
                    placeholder: ratePlatinum,
                    seniorityRates,
                    ratesIndex: index,
                    name: 'ratePlatinum',
                  },
                  parentField: {
                    name: ['ratecardId'],
                    valueGetter: rateCardsParentFieldValueGetter,
                  },
                  validationRules: {
                    isRates: true,
                    isNumeric: true,
                    maxValue: 99999.99,
                  },
                  cssRules: rateCardInputFieldCSS,
                },
                {
                  type: 'text',
                  name: `clientRatecardRates[${index}].rateMo`,
                  label: 'rateMo',
                  fieldData: {
                    currentRateCardRate: currentTemplateRatecard && currentTemplateRatecard.rates[index] ? currentTemplateRatecard.rates[index].rateMo : '',
                    placeholder: rateMo,
                    seniorityRates,
                    ratesIndex: index,
                    name: 'rateMo',
                  },
                  parentField: {
                    name: ['ratecardId'],
                    valueGetter: rateCardsParentFieldValueGetter,
                  },
                  validationRules: {
                    isRates: true,
                    isNumeric: true,
                    maxValue: 99999.99,
                  },
                  cssRules: rateCardInputFieldCSS,
                },
                {
                  type: 'text',
                  name: `clientRatecardRates[${index}].rateMoPlatinum`,
                  label: 'rateMoPlatinum',
                  fieldData: {
                    currentRateCardRate: currentTemplateRatecard && currentTemplateRatecard.rates[index] ? currentTemplateRatecard.rates[index].rateMoPlatinum : '',
                    placeholder: rateMoPlatinum,
                    seniorityRates,
                    ratesIndex: index,
                    name: 'rateMoPlatinum',
                  },
                  parentField: {
                    name: ['ratecardId'],
                    valueGetter: rateCardsParentFieldValueGetter,
                  },
                  validationRules: {
                    isRates: true,
                    isNumeric: true,
                    maxValue: 99999.99,
                  },
                  cssRules: rateCardInputFieldCSS,
                }],
                stylesTemplate: {
                  cssRules: `
                  grid-template-columns: minmax(8rem, 0.8fr) minmax(12rem, 1fr) repeat(6, minmax(8rem, 0.8fr));
                  grid-column-gap: 0.8rem;
                  grid-row-gap: 0.4rem;
                  margin-top: 0;
                  padding: 0.4rem 0 0;
                  `,
                },
              };
            },
            headersConfig: {
              stylesTemplate: {
                cssRules: `
                  && {
                    grid-template-columns: minmax(8rem, 0.8fr) minmax(12rem, 1fr) repeat(6, minmax(8rem, 0.8fr));
                    padding: 0;
                    grid-column-gap: 0.8rem;
                    margin-top: 0;
                  }
                `,
              },
              data: [{
                value: {
                  title: 'Level',
                },
              },
              {
                value: {
                  title: 'DevCenter',
                },
              },
              {
                value: {
                  title: 'PTM rate/hr',
                  cssRules: 'text-align: right;',
                },
              },
              {
                value: {
                  title: 'TTM rate/hr',
                  cssRules: 'text-align: right;',
                },
              },
              {
                value: {
                  title: 'TTM preferred/hr',
                  cssRules: 'text-align: right;',
                },
              },
              {
                value: {
                  title: 'TTM platinum/hr',
                  cssRules: 'text-align: right;',
                },
              },
              {
                value: {
                  title: 'ODC/mo',
                  cssRules: 'text-align: right;',
                },
              },
              {
                value: {
                  title: 'ODC platinum/mo',
                  cssRules: 'text-align: right;',
                },
              },
              ],
            },
          };
        },
        validationRules: {
          schemaGetter: () => array().of(object().shape({
            ratePtm: number().nullable().required('Required field'),
            rateHr: number().nullable().required('Required field'),
            rateDisc: number().nullable().required('Required field'),
            ratePlatinum: number().nullable().required('Required field'),
            rateMo: number().nullable().required('Required field'),
            rateMoPlatinum: number().nullable().required('Required field'),
          })),
        },
      }],
    },
    ],
  }];
};
