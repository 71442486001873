import { isEmpty } from 'lodash';

export const useFilter = ({ isInternal, internalCategories, selected, clientCategories }) => {
  const css = {
    gridTemplateConfig: `
      top: 11.8rem;
      grid-template-columns: ${isInternal ? '4fr 1fr' : '3fr 1fr 1fr'};
      margin-bottom: 2rem;

      & .filter-select__menu {
        right: 0 !important;
        z-index: 2 !important;
      }

      & .grid-unit--filter {
        background-color: #f2f2f2;
      }
    `,
  };

  const filterConfig = [
    {
      type: 'search',
      filterConfig: {
        storeKey: 'search',
        placeholder: 'Search employee',
        isActive: Boolean(!isEmpty(selected.search)),
        selected: '',
      },
    },
    {
      type: 'select',
      filterConfig: {
        storeKey: isInternal ? 'internalCategories' : 'clientCategories',
        isActive: Boolean(!isEmpty(selected.clientCategories)),
        selected: isInternal ? selected.internalCategories.map((item) => item.name).filter(Boolean) : selected.clientCategories.map((item) => item.name).filter(Boolean),
        items: isInternal ? internalCategories.map((item) => item.name).filter(Boolean) : clientCategories.map((item) => item.name).filter(Boolean),
      },
    },
  ];

  if (!isInternal) {
    filterConfig.push(
      {
        type: 'select',
        filterConfig: {
          storeKey: 'internalCategories',
          isActive: Boolean(!isEmpty(selected.internalCategories)),
          selected: selected.internalCategories.map((item) => item.name).filter(Boolean),
          items: internalCategories.map((item) => item.name).filter(Boolean),
        },
      },
    );
  }

  return {
    css,
    filterConfig,
  };
};
