import React, { Fragment, useMemo } from 'react';

import { calculateSingleWorklog } from '../../utils';
import { get } from 'lodash';
import moment from 'moment';

export const useReportTable = (data, type, totals, label, isInternal) => {
  const { columns, memoizedData } = useMemo(() => {
    const baseColumns = [
      {
        Header: type === 'Employee' ? 'Date' : 'Employee',
        accessor: type === 'Employee' ? 'logDate' : 'resName',
        Cell: (cellInfo) => {
          const logDate = get(cellInfo, 'row.original.logDate', null);
          const resName = get(cellInfo, 'row.original.resName', null);
          return <p>{type === 'Employee' ? moment(logDate).format('MM/DD/YYYY') : resName}</p>;
        },
        isSort: false,
        Footer: () => <p>Total for {label}</p>,
        colSpanFooter: 4,
      },
      {
        Header: 'Time Spent',
        accessor: 'workLogs',
        Cell: (cellInfo) => {
          const original = get(cellInfo, 'row.original', null);
          const workLogs = get(original, 'workLogs', null);
          return (
            <ul>
              {workLogs.map((workLog, index) => {
                const result = calculateSingleWorklog(workLog);
                return (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <Fragment key={`${original.resId || original.logDate}_${index}`}>
                    <li data-overtime={workLog.body.includes('[overtime]')}>
                      {`${result.totalHours}h ${result.totalMinutes}m`}
                    </li>
                    <li data-overtime={workLog.body.includes('[overtime]')}>
                      {workLog.body}
                    </li>
                  </Fragment>
                );
              })}
            </ul>
          );
        },
        isSort: false,
        colSpan: isInternal ? 3 : 2,
        Footer: () => null,
      },
      {
        Header: 'Description',
        accessor: 'body',
        Cell: () => 'Description',
        isSort: false,
        isHiddenBody: true,
        Footer: () => null,
      },
      {
        Header: () => isInternal ? 'Internal Category' : 'Client Category',
        accessor: isInternal ? 'internalCategory' : 'clientCategory',
        isSort: false,
        Footer: () => <p>{totals.totalHours}h {totals.totalMinutes}m</p>,
        colSpanHeader: isInternal ? 2 : 1,
      },
    ];

    if (!isInternal) {
      baseColumns.push({
        Header: 'Billing Category',
        accessor: 'internalCategory',
        isSort: false,
        Footer: () => null,
      });
    }

    return {
      memoizedData: data,
      columns: baseColumns,
    };
  }, [data, type, label, totals, isInternal]);

  return { columns, data: memoizedData };
};
