import { REPORTS_TYPES } from 'core/billing-reports/constants';

import { USERS_GROUPS } from './constants';

const {
  CTO,
  CEO,
  CPS,
  OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  CSO,
  SALES,
  HRM,
  RM,
  ACC_MSQ,
  ACC_TBS,
  ACC_NQZ,
  ACC_WAW,
  ACC_SPB,
  HR,
  ADMIN,
} = USERS_GROUPS;

export const fullAccess = [
  CTO,
  CEO,
  CPS,
  OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  CSO,
  SALES,
  HRM,
  RM,
  ACC_MSQ,
  ACC_TBS,
  ACC_NQZ,
  ACC_WAW,
  ACC_SPB,
  HR,
  ADMIN,
];

export const deleteTaskOrderFiles = [CEO, OFFICE_ADMIN, CSO, RM, SALES, HRM];

export const sendForSignature = [CEO, OFFICE_ADMIN, CSO, RM, SALES, HRM];

export const addClientDocuments = [CEO, OFFICE_ADMIN, CSO, SALES];

export const addChannelPartnerDocuments = [CEO, OFFICE_ADMIN, CSO, SALES];

export const signClientDocuments = [CEO, OFFICE_ADMIN, CSO, SALES];

export const deleteClientDocuments = [CEO, OFFICE_ADMIN];

export const deleteChannelPartnerDocuments = [CEO, OFFICE_ADMIN];

export const clientsListActions = [CEO, OFFICE_ADMIN, CPS];

export const createNewClient = [CEO, OFFICE_ADMIN, CSO, RM, SALES];

export const createNewChannelPartner = [CEO, OFFICE_ADMIN, CSO, RM, SALES];

export const createNewTaskOrder = [CEO, OFFICE_ADMIN, CSO, RM, SALES, HRM];

export const taskOrdersControls = [CEO, OFFICE_ADMIN, CSO, RM, SALES, HRM];

export const clientCardHeader = [CEO, OFFICE_ADMIN];

export const taskOrderDetailsHeadingActions = [CEO, OFFICE_ADMIN, HRM];

export const documentUpload = [CEO, OFFICE_ADMIN, CSO, SALES, HRM];

export const documentGenerate = [CEO, OFFICE_ADMIN, CSO, RM, SALES, HRM];

export const taskOrdersDocumentGenerate = [
  CEO,
  CTO,
  OFFICE_ADMIN,
  CSO,
  RM,
  SALES,
  HRM,
];

export const assignedProjects = [CEO, OFFICE_ADMIN, CSO, RM, SALES, HRM];

export const salaryReports = [CEO, CTO, CPS];

export const payrollReports = [CEO, CTO, DELIVERY_OFFICE_ADMIN, ACC_WAW, ACC_TBS, ACC_NQZ, OFFICE_ADMIN, ACC_SPB, CPS];

export const deleteStaffingRows = [CEO, OFFICE_ADMIN, HRM];

export const notRevenue = [HRM];

/* ****************************************************** */
// route: 'home' module: 'Dashboard'
export const dashboardAccess = [
  CEO,
  CTO,
  OFFICE_ADMIN,
  HRM,
  CSO,
  CPS,
  SALES,
  RM,
  DELIVERY_OFFICE_ADMIN,
  ACC_WAW,
  ACC_TBS,
  ACC_NQZ,
];
export const updateWBRecordsOnDashboard = [
  CEO,
  CTO,
  DELIVERY_OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  ACC_WAW,
  ACC_TBS,
  ACC_NQZ,
  OFFICE_ADMIN,
];
export const wbDataOnDashboard = [
  CEO,
  CTO,
  CPS,
  DELIVERY_OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  ACC_WAW,
  ACC_TBS,
  ACC_NQZ,
  OFFICE_ADMIN,
];
export const ptoDataOnDashboard = [CEO, CTO, RM, HRM];

// route: 'clients' module: 'Clients'
export const clientsModuleAccess = [
  CEO,
  CTO,
  OFFICE_ADMIN,
  CSO,
  SALES,
  HRM,
  DELIVERY_OFFICE_ADMIN,
  CPS,
];

export const changeAccountManagerExpirationDate = [CEO, OFFICE_ADMIN];
export const updateClientDetails = [CEO, OFFICE_ADMIN, CSO, SALES];
export const updatePaymentTerms = [CEO, OFFICE_ADMIN];
export const updateOvertimeSurcharge = [CEO, OFFICE_ADMIN];
export const changeDateFirstInvoice = [CEO, OFFICE_ADMIN];
export const changeBillingAgentLang = [CEO, OFFICE_ADMIN];
export const changeConnectedClient = [CEO, OFFICE_ADMIN];
export const changeAccountManager = [CEO, OFFICE_ADMIN];
export const toggleIsPORequired = [CEO, OFFICE_ADMIN];
export const changeBillingAgent = [CEO, OFFICE_ADMIN];
export const changeOwner = [CEO, OFFICE_ADMIN];

// route: 'channel-partners' module: 'Channel partners'

export const channelPartnersModuleAccess = [
  CEO,
  CTO,
  OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN,
  CSO,
  SALES,
  HRM,
  CPS,
];
export const channelPartnerDetailAccess = [
  CEO,
  CTO,
  OFFICE_ADMIN,
  CSO,
  SALES,
  HRM,
  CPS,
];
export const updateChannelPartnersDetails = [...updateClientDetails];
export const changeEmailReports = [CEO, OFFICE_ADMIN, CSO, SALES];

// route: 'task-orders' module: 'TO'
export const taskOrderModuleAccess = [
  CEO,
  CTO,
  OFFICE_ADMIN,
  CSO,
  SALES,
  HRM,
  CPS,
];

export const taskOrdersTableOnDashboard = [
  CEO,
  CTO,
  OFFICE_ADMIN,
  CSO,
  SALES,
  HRM,
  CPS,
];

// no route yet
export const confRootAccess = [
  CEO,
  OFFICE_ADMIN,
  CTO,
  CSO,
  SALES,
  DELIVERY_OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  HRM,
  RM,
  ACC_WAW,
  CPS,
  ADMIN,
];

export const confExchangeRatesManagementAccess = {
  roles: [CEO, OFFICE_ADMIN, CTO, DELIVERY_OFFICE_ADMIN, ACC_WAW, CPS],
  route: 'configurations/exchange-rates-management',
};

export const confEmailTemplateManagementAccess = {
  roles: [CEO],
  route: 'configurations/email-template-management',
};

export const confEmailNotificationsAccess = {
  roles: [CEO],
  route: 'configurations/email-template-notification',
};

export const confRateCardManagementAccess = {
  roles: [CEO, OFFICE_ADMIN, CSO, CPS],
  route: 'configurations/rate-card-management',
};

export const confRateChangeNotificationsAccess = {
  roles: [CEO, OFFICE_ADMIN, CSO, SALES, CPS],
  route: 'configurations/rate-change-notification',
  accessToEditRecords: [CEO, OFFICE_ADMIN, CSO, SALES],
  accessToControlButtons: [CEO, OFFICE_ADMIN],
};

export const confNoneWorkingDaysManagementAccess = {
  roles: [CEO, OFFICE_ADMIN, CTO, HRM, RM, CPS],
  route: 'configurations/none-working-days-management',
};

export const confHiqoContractsManagementAccess = {
  roles: [
    DELIVERY_OFFICE_ADMIN_BY,
    DELIVERY_OFFICE_ADMIN_SPB,
    DELIVERY_OFFICE_ADMIN_WAW,
    DELIVERY_OFFICE_ADMIN_TBS,
    DELIVERY_OFFICE_ADMIN_KZH,
    ACC_WAW,
    CEO,
    CPS,
  ],
  route: 'configurations/hiqo-contracts-management',
};

export const confCommissionRatesAccess = {
  roles: [CEO, CPS],
  route: 'configurations/commission-rates',
};

// route: 'delivery/project-leads-management',
export const confProjectLeadManagementAccess = {
  roles: [CEO, CTO, HRM, RM, CPS],
  route: 'delivery/project-leads-management',
};

export const confDiscountSurchargeManagementAccess = {
  // for release
  // roles: [],
  roles: [CEO, OFFICE_ADMIN],
  route: 'configurations/discount-surcharge-management',
};

export const deletePLAssignments = [CEO, CTO];

// url: '/users/users.php'
export const confUserManagementAccess = {
  roles: [CEO, ADMIN],
  route: 'configurations/user-management',
};

// route: 'delivery'
export const deliveryRootModuleAccess = [CEO, CTO, RM, HRM, CPS, DELIVERY_OFFICE_ADMIN, ACC_TBS, ACC_NQZ, ACC_WAW, OFFICE_ADMIN, HR, ACC_SPB];

// root delivery utilization reports
export const deliveryUtilizationAccess = [CEO, OFFICE_ADMIN, CTO, RM, HRM, CPS];

// action generate today utilization all report
export const todayUtilizationReportAccess = {
  roles: deliveryUtilizationAccess,
  route: 'reports/today-utilization',
}

// url: '/reports/report_util_month.php',
export const utilizationForMonthAccess = {
  roles: [CEO, OFFICE_ADMIN, CTO, RM, HRM, CPS],
  route: 'reports/utilization-for-month',
};

// url: 'report_util_year.php',
export const utilizationForYearAccess = {
  roles: [CEO, OFFICE_ADMIN, CTO, CPS],
  route: 'reports/utilization-for-year',
};

// root delivery bookingAccess
export const deliveryBookingRootAccess = [
  CEO,
  OFFICE_ADMIN,
  CTO,
  RM,
  HRM,
  DELIVERY_OFFICE_ADMIN,
  ACC_WAW,
  ACC_TBS,
  ACC_NQZ,
  CPS,
];

// url: '/pdfReports/resourceBookingsReport.php',
export const resourceBookingReportAccess = {
  roles: [CEO, OFFICE_ADMIN, CTO, RM, HRM, CPS],
  route: 'reports/resource-booking-report',
};

// root sales utilization
export const salesUtilizationRootAccess = [
  CEO,
  OFFICE_ADMIN,
  CTO,
  CSO,
  SALES,
  RM,
  HRM,
  CPS,
];

// action generate todays CFR
export const todaysCFRReportAccess = {
  roles: [
    CEO,
    OFFICE_ADMIN,
    CTO,
    CSO,
    SALES,
    RM,
    HRM,
    CPS,
  ],
  route: 'reports/todays-cfr-report',
};

// staff reports root access
export const staffReportsRootAccess = [
  CEO,
  OFFICE_ADMIN,
  CTO,
  RM,
  HRM,
  DELIVERY_OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  ACC_MSQ,
  ACC_TBS,
  ACC_NQZ,
  ACC_WAW,
  ACC_SPB,
  CPS,
  HR,
  SALES,
  CSO,
];

// url: '/reports/report_sal_year.php',
export const annualPayrollReportAccess = {
  roles: [
    CEO,
    OFFICE_ADMIN,
    CTO,
    DELIVERY_OFFICE_ADMIN,
    ACC_MSQ,
    ACC_TBS,
    ACC_WAW,
    ACC_SPB,
    ACC_NQZ,
    CPS,
  ],
  route: 'reports/annual-report/staff',
};

export const ptoReportAccess = {
  roles: [
    CEO,
    CPS,
    OFFICE_ADMIN,
    DELIVERY_OFFICE_ADMIN,
    CTO,
    RM,
    HRM,
    ACC_WAW,
    ACC_TBS,
    ACC_SPB,
    ACC_MSQ,
    ACC_NQZ,
  ],
  route: 'reports/pto-report',
};

export const updateEmployeeDetails = [CEO, CTO, DELIVERY_OFFICE_ADMIN, ACC_TBS, ACC_NQZ, ACC_WAW, OFFICE_ADMIN];
export const updateWBDetails = (additionalRoles = []) => Array.from(new Set([CEO, DELIVERY_OFFICE_ADMIN, ACC_TBS, ACC_NQZ, ACC_WAW, OFFICE_ADMIN, HR, ...additionalRoles]));
export const devCenterDirectors = (additionalRoles = []) => Array.from(new Set([DELIVERY_OFFICE_ADMIN, ACC_TBS, ACC_NQZ, ACC_WAW, OFFICE_ADMIN, ...additionalRoles]));
export const updatePTODetails = [CEO, CTO, HRM];
export const approvePTO = [CEO, CTO, HRM];
export const permissionsToProjectLeadAssignmentsTab = [CEO, CTO, RM, HRM, CPS];

export const deliveryResourcesAccess = {
  roles: [CEO, CTO, RM, HRM, CPS, DELIVERY_OFFICE_ADMIN, ACC_TBS, ACC_NQZ, ACC_WAW, OFFICE_ADMIN, HR, ACC_SPB],
  route: 'delivery/employees-list',
};

export const resourceManagementReportLinkAccess = [CEO, CTO, RM, HRM, ACC_TBS, ACC_NQZ, ACC_WAW, DELIVERY_OFFICE_ADMIN, CPS, CPS, OFFICE_ADMIN, HR, ACC_SPB];

export const deliveryReviewsManagementAccess = {
  roles: [CEO, CTO, RM, HRM, CPS],
  route: 'devstaff-reviews/management',
};

export const deliveryReviewsReportAccess = {
  roles: [CEO, CTO, RM, HRM, HR, ACC_TBS, ACC_NQZ, ACC_WAW, DELIVERY_OFFICE_ADMIN, CPS, ACC_NQZ],
  route: 'devstaff-reviews/report',
};

export const requiredReviewsReportAccess = {
  roles: [CEO, CTO, RM, HRM, CPS],
  route: 'reports/required-reviews',
};

export const resourceManagementReportAccess = {
  roles: [CEO, CTO, RM, HRM, ACC_TBS, ACC_NQZ, ACC_WAW, ACC_MSQ, ACC_SPB, DELIVERY_OFFICE_ADMIN, CPS, CPS, OFFICE_ADMIN, HR],
  route: 'reports/resource-management-report/onboarding',
};

export const mentoringCompensationReportAccess = {
  roles: [CEO, CTO, OFFICE_ADMIN, RM, HRM, CPS, DELIVERY_OFFICE_ADMIN, ACC_TBS, ACC_WAW, ACC_MSQ, ACC_SPB, ACC_NQZ],
  route: 'reports/mentoring-compensation-report',
};

export const staffWorklogsReportAccess = {
  roles: [CEO, CTO, RM, HRM, CPS],
  route: 'reports/staff-worklogs',
};

export const reconciliationReportAccess = {
  roles: [CEO, CTO, RM, HRM, OFFICE_ADMIN, CPS, SALES, CSO],
  route: 'reconciliation/report',
};

export const currentPayrollReportAccess = {
  roles: [
    CEO,
    OFFICE_ADMIN,
    CTO,
    DELIVERY_OFFICE_ADMIN,
    ACC_WAW,
    ACC_TBS,
    ACC_SPB,
    ACC_NQZ,
    CPS,
  ],
  route: 'reports/current-payroll-report',
};

export const billingReportsRootAccess = [
  CEO,
  OFFICE_ADMIN,
  CTO,
  CSO,
  SALES,
  RM,
  HRM,
  DELIVERY_OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  ACC_MSQ,
  ACC_TBS,
  ACC_WAW,
  ACC_SPB,
  ACC_NQZ,
  CPS,
  HR,
];

// no route yet
export const billingReportsToRootAccess = [CEO, OFFICE_ADMIN, CSO, SALES, CPS];

export const billingReportsProjectToAccess = {
  route: `reports/billing-reports/project/${REPORTS_TYPES.TO}`,
  roles: [CEO, OFFICE_ADMIN, CSO, SALES, CPS],
};

export const billingReportsSummaryToAccess = {
  route: `reports/billing-reports/summary/${REPORTS_TYPES.TO}`,
  roles: [CEO, OFFICE_ADMIN, CSO, SALES, CPS],
};

export const billingReportsSummaryGmAccess = {
  route: `reports/billing-reports/summary/${REPORTS_TYPES.GM}`,
  roles: [CEO, OFFICE_ADMIN, CPS],
};

export const internalToAccess = {
  route: 'reports/billing-reports/internal-to',
  roles: [
    CEO,
    DELIVERY_OFFICE_ADMIN,
    DELIVERY_OFFICE_ADMIN_BY,
    DELIVERY_OFFICE_ADMIN_SPB,
    DELIVERY_OFFICE_ADMIN_WAW,
    DELIVERY_OFFICE_ADMIN_TBS,
    DELIVERY_OFFICE_ADMIN_KZH,
    OFFICE_ADMIN,
    ACC_WAW,
    ACC_TBS,
    ACC_NQZ,
    CPS,
  ],
};

export const utilizationsByMonthAccess = {
  route: 'reports/utilization-by-month',
  roles: [CEO, OFFICE_ADMIN, CTO, RM, HRM, CPS],
};

// no route yet
export const billingReportsDtoRootAccess = [
  CEO,
  OFFICE_ADMIN,
  CTO,
  HRM,
  RM,
  DELIVERY_OFFICE_ADMIN,
  DELIVERY_OFFICE_ADMIN_BY,
  DELIVERY_OFFICE_ADMIN_SPB,
  DELIVERY_OFFICE_ADMIN_WAW,
  DELIVERY_OFFICE_ADMIN_TBS,
  DELIVERY_OFFICE_ADMIN_KZH,
  ACC_WAW,
  CPS,
  ACC_TBS,
  ACC_NQZ,
];
export const billingReportsProjectDtoAccess = {
  route: `reports/billing-reports/project/${REPORTS_TYPES.DTO}`,
  roles: [CEO, OFFICE_ADMIN, CTO, HRM, RM, DELIVERY_OFFICE_ADMIN, CPS, ACC_TBS, ACC_WAW, ACC_NQZ],
};

export const billingReportsSummaryDtoAccess = {
  route: `reports/billing-reports/summary/${REPORTS_TYPES.DTO}`,
  roles: [
    CEO,
    OFFICE_ADMIN,
    CTO,
    HRM,
    RM,
    DELIVERY_OFFICE_ADMIN,
    DELIVERY_OFFICE_ADMIN_BY,
    DELIVERY_OFFICE_ADMIN_SPB,
    DELIVERY_OFFICE_ADMIN_WAW,
    DELIVERY_OFFICE_ADMIN_TBS,
    DELIVERY_OFFICE_ADMIN_KZH,
    ACC_WAW,
    CPS,
    ACC_TBS,
    ACC_NQZ,
  ],
};

// url: '/reports/report_billings_day.php',
export const todayBillingReportAccess = {
  roles: [
    CEO,
    OFFICE_ADMIN,
    CTO,
    DELIVERY_OFFICE_ADMIN,
    HRM,
    RM,
    ACC_WAW,
    ACC_TBS,
    ACC_NQZ,
    CPS,
  ],
  route: 'reports/delivery/today-billing',
};

export const salesReportsAccess = {
  route: 'reports/sales-reports',
  roles: [CEO, OFFICE_ADMIN, CSO, SALES, CPS],
};

export const plCommissionReportAccess = {
  route: 'reports/project-lead-commission',
  roles: [
    CEO,
    OFFICE_ADMIN,
    CTO,
    DELIVERY_OFFICE_ADMIN,
    DELIVERY_OFFICE_ADMIN_BY,
    DELIVERY_OFFICE_ADMIN_SPB,
    DELIVERY_OFFICE_ADMIN_WAW,
    DELIVERY_OFFICE_ADMIN_TBS,
    DELIVERY_OFFICE_ADMIN_KZH,
    ACC_MSQ,
    ACC_TBS,
    ACC_WAW,
    ACC_SPB,
    ACC_NQZ,
    CPS,
  ],
};

export const confCategoryManagement = {
  route: 'configurations/category-management',
  roles: [CEO, CTO, RM, HRM, CPS],
  childrenRoutes: {
    client: 'configurations/category-management/client',
  },
};

export const createResourceAccess = {
  route: 'employee/add/details',
  roles: [CEO, CTO, DELIVERY_OFFICE_ADMIN, ACC_TBS, ACC_WAW, ACC_NQZ, OFFICE_ADMIN, HR],
};

export const resourceTabsEditRecord = (additionalRoles = []) => Array.from(new Set([CEO, CTO, ...additionalRoles]));
export const resourceTabsEditRecordOnlyThreeDays = (additionalRoles = []) => Array.from(new Set([ACC_TBS, ACC_WAW, DELIVERY_OFFICE_ADMIN, HRM, RM, OFFICE_ADMIN, ACC_NQZ, ...additionalRoles]));
export const resourceTabsViewRecord = (additionalRoles = []) => Array.from(new Set([CPS, ...additionalRoles]));

export const resourceContactManagementAccess = {
  rolesToEdit: resourceTabsEditRecord(),
  rolesToView: resourceTabsViewRecord(),
  rolesToEditOnlyThreeDays: resourceTabsEditRecordOnlyThreeDays([HR]),
};

export const resourceContractManagementAccess = {
  rolesToEdit: resourceTabsEditRecord([ACC_TBS, ACC_WAW, DELIVERY_OFFICE_ADMIN, HRM, RM, HR, OFFICE_ADMIN, ACC_NQZ]),
  rolesToView: resourceTabsViewRecord([HR]),
  rolesToViewAttachment: [CEO, CTO, DELIVERY_OFFICE_ADMIN, ACC_WAW, ACC_TBS, ACC_NQZ, OFFICE_ADMIN, CPS, ACC_SPB],
};

export const resourceCommentManagementAccess = {
  rolesToEdit: resourceTabsEditRecord(),
  rolesToView: resourceTabsViewRecord(),
  rolesToEditOnlyThreeDays: resourceTabsEditRecordOnlyThreeDays([HR]),
};

export const resourceAttachmentManagementAccess = {
  rolesToView: resourceTabsViewRecord(),
  rolesToAddRecord: resourceTabsEditRecordOnlyThreeDays([CEO, CTO, HR]),
};

export const resourceTechnicalMentorAccess = {
  rolesToAdd: resourceTabsEditRecord([DELIVERY_OFFICE_ADMIN, ACC_TBS, ACC_WAW, ACC_NQZ, OFFICE_ADMIN, HRM, RM, HR]),
};

export const customerTabAccess = [CEO, OFFICE_ADMIN, DELIVERY_OFFICE_ADMIN];
export const resourceBillingReportAccess = [CEO, CTO, RM, HRM, OFFICE_ADMIN, DELIVERY_OFFICE_ADMIN, ACC_WAW, ACC_TBS, ACC_NQZ, CPS];

export const configurationGuaranteedAccessRolesForSubRoutes = {
  [`/${confExchangeRatesManagementAccess.route}`]:
  confExchangeRatesManagementAccess.roles,
  [`/${confCommissionRatesAccess.route}`]: confCommissionRatesAccess.roles,
  [`/${confEmailNotificationsAccess.route}`]: confEmailNotificationsAccess.roles,
  [`/${confRateCardManagementAccess.route}`]: confRateCardManagementAccess.roles,
  [`/${confRateChangeNotificationsAccess.route}`]: confRateChangeNotificationsAccess.roles,
  [`/${confNoneWorkingDaysManagementAccess.route}`]: confNoneWorkingDaysManagementAccess.roles,
  [`/${confHiqoContractsManagementAccess.route}`]: confHiqoContractsManagementAccess.roles,
  [`/${confEmailTemplateManagementAccess.route}`]: confEmailTemplateManagementAccess.roles,
  [`/${confCategoryManagement.route}`]: confCategoryManagement.roles,
  [`/${confDiscountSurchargeManagementAccess.route}`]: confDiscountSurchargeManagementAccess.roles,
  [`/${confUserManagementAccess.route}`]: confUserManagementAccess.roles,
};

export const reportsGuaranteedAccessRolesForSubRoutes = {
  [`/${billingReportsProjectToAccess.route}`]:
  billingReportsProjectToAccess.roles,
  [`/${billingReportsSummaryToAccess.route}`]:
  billingReportsSummaryToAccess.roles,
  [`/${billingReportsSummaryGmAccess.route}`]:
  billingReportsSummaryGmAccess.roles,
  [`/${billingReportsProjectDtoAccess.route}`]:
  billingReportsProjectDtoAccess.roles,
  [`/${billingReportsSummaryDtoAccess.route}`]:
  billingReportsSummaryDtoAccess.roles,
  [`/${internalToAccess.route}`]: internalToAccess.roles,
  [`/${salesReportsAccess.route}`]: salesReportsAccess.roles,
  [`/${plCommissionReportAccess.route}`]: plCommissionReportAccess.roles,
  '/reports/annual-report': annualPayrollReportAccess.roles,
  [`/${todayBillingReportAccess.route}`]: todayBillingReportAccess.roles,
  [`/${reconciliationReportAccess.route}`]:
  reconciliationReportAccess.roles,
  [`/${utilizationForYearAccess.route}`]: utilizationForYearAccess.roles,
  [`/${currentPayrollReportAccess.route}`]:
  currentPayrollReportAccess.roles,
  [`/${utilizationsByMonthAccess.route}`]: utilizationsByMonthAccess.roles,
  [`/${utilizationForMonthAccess.route}`]: utilizationForMonthAccess.roles,
  '/reports/resource-management-report': resourceManagementReportAccess.roles,
  [`/${deliveryReviewsReportAccess.route}`]: deliveryReviewsReportAccess.roles,
  [`/${requiredReviewsReportAccess.route}`]: requiredReviewsReportAccess.roles,
  [`/${ptoReportAccess.route}`]: ptoReportAccess.roles,
  [`/${mentoringCompensationReportAccess.route}`]: mentoringCompensationReportAccess.roles,
  [`/${staffWorklogsReportAccess.route}`]: staffWorklogsReportAccess.roles,
  [`/${todaysCFRReportAccess.route}`]: todaysCFRReportAccess.roles,
  [`/${todayUtilizationReportAccess.route}`]: todayUtilizationReportAccess.roles,
  [`/${resourceBookingReportAccess.route}`]:
  resourceBookingReportAccess.roles,
};

export const deliveryGuaranteedAccessRolesForSubRoutes = {
  [`/${deliveryReviewsManagementAccess.route}`]:
  deliveryReviewsManagementAccess.roles,
  [`/${confProjectLeadManagementAccess.route}`]:
  confProjectLeadManagementAccess.roles,
  [`/${deliveryResourcesAccess.route}`]:
  deliveryResourcesAccess.roles,
  [`/${deliveryReviewsReportAccess.route}`]: deliveryReviewsReportAccess.roles,
  '/delivery/employee': deliveryResourcesAccess.roles,
  [`/delivery/${createResourceAccess.route}`]: createResourceAccess.roles,
};

export const reviewsLinkAccess = [CEO, CPS, CTO, DELIVERY_OFFICE_ADMIN, RM, HRM, ACC_TBS, ACC_NQZ, ACC_WAW, HR];
