import React, { useMemo } from 'react';

import { ReactComponent as DropdownIcon } from 'assets/img/arrow-down.svg';
import { ReactComponent as InfoIcon } from 'assets/img/icon-info.svg';
import classNames from 'classnames';
import { ActionButton } from 'components/buttons';
import StarsRating from 'elements/stars-rating';
import { get, isEmpty, isNull } from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import styles from './style.module.scss';

const customSort = (rowA, rowB, columnId) => {
  let valueA = rowA.values[columnId];
  let valueB = rowB.values[columnId];

  if (columnId === 'missingWeeks') {
    valueA = rowA.values[columnId].length;
    valueB = rowB.values[columnId].length;
  }

  if (valueA === '-' && valueB === '-') {
    return false;
  }

  if (valueA === valueB) {
    return false;
  }

  return valueA > valueB ? 1 : -1;
};

export const useReportTable = (data, collapseReviews, toggleCollapse) => {
  const { columns, memoizedData } = useMemo(() => ({
    memoizedData: data,
    columns: [
      {
        Header: 'Resource',
        accessor: 'resFullname',
        Cell: (cellInfo) => {
          const id = get(cellInfo, 'row.original.resId', null);
          const name = get(cellInfo, 'row.original.resFullname', '-');
          const lastReview = get(cellInfo, 'row.original.lastReview', null);
          if (!id || !lastReview) {
            return <p>{name}</p>;
          }
          return (
            <ActionButton
              dataId={`${name}_${id}`}
              onClick={() => toggleCollapse(id)}
              className={
                classNames({
                  [styles.active]: collapseReviews.includes(id),
                })
              }
            >
              {name}
              <DropdownIcon />
            </ActionButton>
          );
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
        isSort: false,
        Cell: (cellInfo) => <p>{get(cellInfo, 'row.original.role', '-')}</p>,
      },
      {
        Header: 'Category',
        accessor: 'internalCategoryName',
        sortType: customSort,
        Cell: (cellInfo) => <p>{get(cellInfo, 'row.original.internalCategoryName', '-')}</p>,
      },
      {
        Header: 'Missing Weeks',
        accessor: 'missingWeeks',
        sortType: customSort,
        Cell: (cellInfo) => {
          const weeks = get(cellInfo, 'row.original.missingWeeks', []);
          if (isEmpty(weeks)) {
            return <p>-</p>;
          }
          return (
            <>
              <p>
                {weeks.length} weeks
                <InfoIcon
                  data-tip={weeks.join(', ')}
                  data-place="top"
                  data-effect="solid"
                  data-delay-show="10"
                  data-offset="{'top': 0}"
                />
              </p>
              <ReactTooltip />
            </>
          );
        },
      },
      {
        Header: 'Overdue Period',
        accessor: 'missingReviewPeriod',
        sortType: customSort,
        Cell: (cellInfo) => <p>{get(cellInfo, 'row.original.missingReviewPeriod', '-')}</p>,
      },
      {
        Header: 'Last Review Week',
        accessor: 'lastReviewWeek',
        sortType: customSort,
        Cell: (cellInfo) => <p>{get(cellInfo, 'row.original.lastReviewWeek', '-')}</p>,
      },
      {
        Header: 'Previous Review Week',
        accessor: 'previousReviewWeek',
        sortType: customSort,
        Cell: (cellInfo) => <p>{get(cellInfo, 'row.original.previousReviewWeek', '-')}</p>,
      },
      {
        Header: 'Date',
        accessor: 'reviewDate',
        Cell: (cellInfo) => {
          const id = get(cellInfo, 'row.original.resId', null);
          const lastReview = get(cellInfo, 'row.original.lastReview', null);
          if (isNull(lastReview)) {
            return null;
          }
          const weekNumber = lastReview.week;
          const startOfWeek = moment().week(weekNumber).startOf('week');
          const endOfWeek = moment().week(weekNumber).endOf('week');
          const formatString = 'DD MMM';
          const formattedStartOfWeek = startOfWeek.format(formatString);
          const formattedEndOfWeek = endOfWeek.format(formatString);
          return (
            <p
              className={
                classNames(styles.date, {
                  [styles.active]: collapseReviews.includes(id),
                })
              }
            >
              <span>{weekNumber}</span>: {formattedStartOfWeek} -{' '}
              {formattedEndOfWeek}
            </p>
          );
        },
        isHeaderHidden: true,
      },
      {
        Header: 'Rating',
        accessor: 'rating',
        Cell: (cellInfo) => {
          const id = get(cellInfo, 'row.original.resId', null);
          const lastReview = get(cellInfo, 'row.original.lastReview', null);
          if (isNull(lastReview)) {
            return null;
          }
          return (
            <div
              className={
                classNames(styles.rating, {
                  [styles.active]: collapseReviews.includes(id),
                })
              }
            >
              <StarsRating rating={lastReview.rating} isViewMode />
            </div>
          );
        },
        isHeaderHidden: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (cellInfo) => {
          const id = get(cellInfo, 'row.original.resId', null);
          const lastReview = get(cellInfo, 'row.original.lastReview', null);

          if (isNull(lastReview)) {
            return null;
          }
          return (
            <p
              className={
                classNames({
                  [styles.active]: collapseReviews.includes(id),
                })
              }
              dangerouslySetInnerHTML={{ __html: lastReview.review }}
            />
          );
        },
        isHeaderHidden: true,
      },
    ],
  }), [data, collapseReviews]);

  return { columns, data: memoizedData };
};
