import {
  appSagas,
} from './auth/sagas';
import channelPartnersSagas from './channel-partners/sagas';
import clientsSagas from './clients/sagas';
import commonSagas from './common/sagas';
import categoryManagementSagas from './configurations/category-management/sagas';
import userManagementSagas from './configurations/user-management/sagas';
import dashboardSagas from './dashboard/sagas';
import deliverySagas from './delivery/sagas';
import filesSagas from './files/sagas';
import reconciliationSagas from './reconciliation/sagas';
import requiredReviewsReportSagas from './required-reviews-report/sagas';
import staffWorkLogsSagas from './staff-worklogs-report/sagas';
import resourceManagementReportSagas from './resource-management-report/sagas';
import taskOrderSagas from './task-orders/sagas';
import userSagas from './user/sagas';

const sagas = [
  ...appSagas,
  ...clientsSagas,
  ...channelPartnersSagas,
  ...reconciliationSagas,
  ...taskOrderSagas,
  ...filesSagas,
  ...deliverySagas,
  ...dashboardSagas,
  ...commonSagas,
  ...userSagas,
  ...categoryManagementSagas,
  ...userManagementSagas,
  ...resourceManagementReportSagas,
  ...requiredReviewsReportSagas,
  ...staffWorkLogsSagas,
];

export default sagas;
