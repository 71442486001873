import { createAction } from 'redux-actions';

import { async, createTypes } from 'utils/helpers/actions';

import { ENTITY_NAME } from './constants';

export const filesActionsTypes = createTypes([
  ...async('GENERATE_PROJECT_LEAD_COMMISSION_PDF_REPORT'),
  ...async('GENERATE_PROJECT_LEAD_COMMISSION_REPORT'),
  ...async('GENERATE_CURRENT_PAYROLL_REPORT'),
  ...async('GENERATE_CURRENT_PAYROLL_EXCEL_REPORT'),
  ...async('GENERATE_BILLING_PROJECT_REPORT'),
  ...async('GENERATE_BILLING_PROJECT_EXCEL_REPORT'),
  ...async('GENERATE_BILLING_PROJECT_TO_EXCEL_REPORT'),
  ...async('GENERATE_ANNUAL_PAYROLL_REPORT'),
  ...async('GENERATE_ANNUAL_PAYROLL_EXCEL_REPORT'),
  ...async('GENERATE_ANNUAL_PAYROLL_SALARY_REPORT'),
  ...async('GENERATE_UTILIZATION_REPORT'),
  ...async('GENERATE_TODAY_BILLING_REPORT'),
  ...async('GENERATE_RECONCILIATION_REPORT'),
  ...async('GENERATE_RECONCILIATION_SECOND_REPORT'),
  ...async('GET_TODAY_CFR_REPORT'),
  ...async('GENERATE_PTO_REPORT'),
  ...async('GENERATE_INTERNAL_TO_EXCEL_REPORT'),
  ...async('UPLOAD_FILE'),
  'UPDATE_UPLOADING_PROGRESS',
  'SIGN_DOCUMENT',
  ...async('GENERATE_UTILIZATION_FOR_YEAR_REPORT'),
  ...async('GENERATE_UTILIZATION_FOR_MONTH_REPORT'),
  ...async('GENERATE_BOOKING_REPORT'),
  ...async('GENERATE_MENTORING_COMPENSATION_EXCEL_REPORT'),
  ...async('GENERATE_CONTRACT_FULFILLMENT_PDF_REPORT'),
  ...async('GENERATE_STAFF_WORKLOGS_REPORT'),
], ENTITY_NAME);

export const filesActions = {
  uploadFile: createAction(
    filesActionsTypes.UPLOAD_FILE,
    ({
      clientId,
      projectId,
      channelPartnerId,
      fileName,
      fileType,
      title,
      file,
      effectiveDate,
      isSigned,
      recordId,
    }) => ({
      clientId,
      projectId,
      channelPartnerId,
      fileName,
      fileType,
      title,
      file,
      effectiveDate,
      isSigned,
      recordId,
    }),
    ({
      entityName,
    }) => ({
      entityName,
    })
  ),

  uploadFileSuccess: createAction(filesActionsTypes.UPLOAD_FILE_SUCCESS),

  uploadFileFail: createAction(
    filesActionsTypes.UPLOAD_FILE_FAIL,
    ({
      error,
    }) => ({
      error,
    })
  ),

  updateUploadingProgress: createAction(
    filesActionsTypes.UPDATE_UPLOADING_PROGRESS,
    ({
      progress,
    }) => ({
      progress,
    })
  ),

  signDocument: createAction(
    filesActionsTypes.SIGN_DOCUMENT,
    ({
      fileId,
      pocEmail,
      pocName,
      message,
    }) => ({
      fileId,
      pocEmail,
      pocName,
      message,
    }),
    ({
      entityName,
    }) => ({
      entityName,
    })
  ),

  generateUtilizationReport: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_REPORT,
    (payload) => (payload),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateUtilizationReportSuccess: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_REPORT_SUCCESS,
    () => ({}),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateUtilizationReportFail: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateUtilizationForYearReport: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_FOR_YEAR_REPORT,
    (payload) => (payload),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateUtilizationForYearReportSuccess: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_FOR_YEAR_REPORT_SUCCESS,
    (payload) => (payload),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateUtilizationForYearReportFail: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_FOR_YEAR_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateUtilizationForMonthReport: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_FOR_MONTH_REPORT,
    (payload) => (payload),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateUtilizationForMonthReportSuccess: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_FOR_MONTH_REPORT_SUCCESS,
    (payload) => (payload),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateUtilizationForMonthReportFail: createAction(
    filesActionsTypes.GENERATE_UTILIZATION_FOR_MONTH_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateBookingReport: createAction(
    filesActionsTypes.GENERATE_BOOKING_REPORT,
    (payload) => (payload),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateBookingReportSuccess: createAction(
    filesActionsTypes.GENERATE_BOOKING_REPORT_SUCCESS,
    (payload) => (payload),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateBookingReportFail: createAction(
    filesActionsTypes.GENERATE_BOOKING_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateCurrentPayrollReport: createAction(
    filesActionsTypes.GENERATE_CURRENT_PAYROLL_REPORT,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateCurrentPayrollExcelReport: createAction(
    filesActionsTypes.GENERATE_CURRENT_PAYROLL_EXCEL_REPORT,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateCurrentPayrollReportSuccess: createAction(
    filesActionsTypes.GENERATE_CURRENT_PAYROLL_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateCurrentPayrollExcelReportSuccess: createAction(
    filesActionsTypes.GENERATE_CURRENT_PAYROLL_EXCEL_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateCurrentPayrollReportFail: createAction(
    filesActionsTypes.GENERATE_CURRENT_PAYROLL_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateCurrentPayrollExcelReportFail: createAction(
    filesActionsTypes.GENERATE_CURRENT_PAYROLL_EXCEL_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generatePtoReport: createAction(
    filesActionsTypes.GENERATE_PTO_REPORT,
    () => ({}),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generatePtoReportSuccess: createAction(
    filesActionsTypes.GENERATE_PTO_REPORT_SUCCESS,
    () => ({}),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generatePtoReportFail: createAction(
    filesActionsTypes.GENERATE_PTO_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateBillingProjectReport: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_REPORT,
    (payload) => payload,
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),

  generateBillingProjectReportSuccess: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_REPORT_SUCCESS,
    () => ({}),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateBillingProjectReportFail: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_REPORT_FAIL,
    ({
      error,
    }) => ({
      error,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateBillingProjectExcelReport: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_EXCEL_REPORT,
    (payload) => (payload),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),

  generateBillingProjectExcelReportSuccess: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_EXCEL_REPORT_SUCCESS,
    () => ({}),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateBillingProjectExcelReportFail: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_EXCEL_REPORT_FAIL,
    ({
      error,
    }) => ({
      error,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateBillingProjectToExcelReport: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_TO_EXCEL_REPORT,
    (payload) => payload,
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),

  generateBillingProjectToExcelReportSuccess: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_TO_EXCEL_REPORT_SUCCESS,
    () => ({}),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateBillingProjectToExcelReportFail: createAction(
    filesActionsTypes.GENERATE_BILLING_PROJECT_TO_EXCEL_REPORT_FAIL,
    ({
      error,
    }) => ({
      error,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateProjectLeadCommissionReport: createAction(
    filesActionsTypes.GENERATE_PROJECT_LEAD_COMMISSION_REPORT,
    () => null,
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),

  generateProjectLeadCommissionReportSuccess: createAction(
    filesActionsTypes.GENERATE_PROJECT_LEAD_COMMISSION_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateProjectLeadCommissionReportFail: createAction(
    filesActionsTypes.GENERATE_PROJECT_LEAD_COMMISSION_REPORT_FAIL,
    ({
      error,
    }) => ({
      error,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateProjectLeadCommissionPDFReport: createAction(
    filesActionsTypes.GENERATE_PROJECT_LEAD_COMMISSION_PDF_REPORT,
    ({ plName } = {}) => ({ plName }),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateProjectLeadCommissionPDFReportSuccess: createAction(
    filesActionsTypes.GENERATE_PROJECT_LEAD_COMMISSION_PDF_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateProjectLeadCommissionPDFReportFail: createAction(
    filesActionsTypes.GENERATE_PROJECT_LEAD_COMMISSION_PDF_REPORT_FAIL,
    ({
      error,
    }) => ({
      error,
    }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateAnnualPayrollReport: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_REPORT,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateAnnualPayrollExcelReport: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_EXCEL_REPORT,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateAnnualPayrollReportSuccess: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateAnnualPayrollExcelReportSuccess: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_EXCEL_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateAnnualPayrollReportFail: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateAnnualPayrollExcelReportFail: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_EXCEL_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateAnnualPayrollSalaryReport: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_SALARY_REPORT,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateAnnualPayrollSalaryReportSuccess: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_SALARY_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateAnnualPayrollSalaryReportFail: createAction(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_SALARY_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  generateTodayBillingReport: createAction(
    filesActionsTypes.GENERATE_TODAY_BILLING_REPORT,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    })
  ),
  generateTodayBillingReportSuccess: createAction(
    filesActionsTypes.GENERATE_TODAY_BILLING_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),
  generateTodayBillingReportFail: createAction(
    filesActionsTypes.GENERATE_TODAY_BILLING_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    })
  ),

  // GENERATE_RECONCILIATION_REPORT
  generateReconciliationReport: createAction(
    filesActionsTypes.GENERATE_RECONCILIATION_REPORT,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    }),
  ),

  generateReconciliationReportSuccess: createAction(
    filesActionsTypes.GENERATE_RECONCILIATION_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateReconciliationReportFail: createAction(
    filesActionsTypes.GENERATE_RECONCILIATION_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),
  generateReconciliationSecondReport: createAction(
    filesActionsTypes.GENERATE_RECONCILIATION_SECOND_REPORT,
    (data) => (data),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    }),
  ),

  generateReconciliationSecondReportSuccess: createAction(
    filesActionsTypes.GENERATE_RECONCILIATION_SECOND_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateReconciliationSecondReportFail: createAction(
    filesActionsTypes.GENERATE_RECONCILIATION_SECOND_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateInternalToExcelReport: createAction(
    filesActionsTypes.GENERATE_INTERNAL_TO_EXCEL_REPORT,
    ({
      month,
      year,
      fields,
    }) => ({
      month,
      year,
      fields,
    }),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    }),
  ),

  generateInternalToExcelReportSuccess: createAction(
    filesActionsTypes.GENERATE_INTERNAL_TO_EXCEL_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateInternalToExcelReportFail: createAction(
    filesActionsTypes.GENERATE_INTERNAL_TO_EXCEL_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateMentoringCompensationExcelReport: createAction(
    filesActionsTypes.GENERATE_MENTORING_COMPENSATION_EXCEL_REPORT,
    (month) => (month),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    }),
  ),

  generateMentoringCompensationExcelReportSuccess: createAction(
    filesActionsTypes.GENERATE_MENTORING_COMPENSATION_EXCEL_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateMentoringCompensationExcelReportFail: createAction(
    filesActionsTypes.GENERATE_MENTORING_COMPENSATION_EXCEL_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateStaffWorkLogsExcelReport: createAction(
    filesActionsTypes.GENERATE_STAFF_WORKLOGS_REPORT,
    (month) => (month),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    }),
  ),

  generateStaffWorkLogsExcelReportSuccess: createAction(
    filesActionsTypes.GENERATE_STAFF_WORKLOGS_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateStaffWorkLogsExcelReportFail: createAction(
    filesActionsTypes.GENERATE_STAFF_WORKLOGS_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateContractFulfillmentReport: createAction(
    filesActionsTypes.GENERATE_CONTRACT_FULFILLMENT_PDF_REPORT,
    (month) => (month),
    () => ({
      modalConductor: {
        nextModal: 'LOADER',
      },
    }),
  ),

  generateContractFulfillmentReportSuccess: createAction(
    filesActionsTypes.GENERATE_MENTORING_COMPENSATION_EXCEL_REPORT_SUCCESS,
    () => null,
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),

  generateContractFulfillmentReportFail: createAction(
    filesActionsTypes.GENERATE_MENTORING_COMPENSATION_EXCEL_REPORT_FAIL,
    ({ error }) => ({ error }),
    () => ({
      modalConductor: {
        nextModal: null,
      },
    }),
  ),
};
