import { waitForAuthorization } from 'core/auth/sagas';

import {
  selectCurrentTaskOrder,
  selectIsChannelPartnerVersion,
  selectProjectReportType,
  selectReportData,
} from 'core/billing-reports/selectors';
import { clientsActions, clientsActionsTypes } from 'core/clients/actions';
import { selectCurrentEntityName } from 'core/common/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import { appStorage } from 'core/storage';
import { taskOrdersActions, taskOrdersActionsTypes } from 'core/task-orders/actions';
import { get, isNil } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { all, call, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import { getNotifications } from 'utils/helpers/notifications';
import request, {
  createUploadFileChannel,
  executeMutation,
  executeQuery,
  getRequestUrl,
  parseError,
} from 'utils/request';

import { filesActions, filesActionsTypes } from './actions';
import { queryConfig } from './queries';
import { selectErrors } from './selectors';
import { getEntity, getFileLoaderEntity, getGeneratorEntity } from './utils';

import { channelPartnersActions, channelPartnersActionsTypes } from '../channel-partners/actions';

function* generateContractFulfillmentPDFReport({ payload }) {
  try {
    const query = queryConfig.todayCfrReport;
    const options = {
      query,
      variables: payload,
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'todayCfrReport.url');
    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateContractFulfillmentReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateContractFulfillmentPDFReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const getTodayCfrReportError = yield call(parseError, error, options);

    return yield put(filesActions.generateContractFulfillmentReportFail({
      error: {
        getTodayCfrReportError,
      },
    }));
  }
}

function* generateAnnualPayrollReport({ payload }) {
  try {
    const query = queryConfig.generateAnnualPayrollReport;
    const options = {
      query,
      variables: {
        fields: payload,
      },
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateAnnualPayrollReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateAnnualPayrollReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateAnnualPayrollReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateAnnualPayrollReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateAnnualPayrollReportFail({
      error: {
        generateAnnualPayrollReportError,
      },
    }));
  }
}

function* generateAnnualPayrollExcelReport({ payload }) {
  try {
    const query = queryConfig.generateAnnualPayrollExcelReport;
    const options = {
      query,
      variables: {
        fields: payload,
      },
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateAnnualPayrollExcelReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateAnnualPayrollExcelReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateAnnualPayrollExcelReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateAnnualPayrollExcelReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateAnnualPayrollExcelReportFail({
      error: {
        generateAnnualPayrollExcelReportError,
      },
    }));
  }
}

function* generateReconciliationReport({ payload }) {
  try {
    const query = queryConfig.generateReconciliationReport;
    const options = {
      query,
      variables: {
        fields: payload,
      },
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateReconciliationReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateReconciliationReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateReconciliationReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateReconciliationReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateReconciliationReportFail({
      error: {
        generateReconciliationReportError,
      },
    }));
  }
}

function* generateReconciliationSecondReport({ payload }) {
  try {
    const query = queryConfig.generateReconciliationSecondReport;
    const options = {
      query,
      variables: {
        fields: payload,
      },
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateReconciliationSecondReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateReconciliationSecondReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateReconciliationSecondReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateReconciliationSecondReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateReconciliationSecondReportFail({
      error: {
        generateReconciliationSecondReportError,
      },
    }));
  }
}

function* generateAnnualPayrollSalaryReport({ payload }) {
  try {
    const mutation = queryConfig.generateAnnualPayrollSalaryReport;
    const options = {
      mutation,
      variables: payload,
    };
    const response = yield call(executeMutation, options);

    const url = get(response, 'generatePayrollReport.url');
    if (url != null) {
      window.location.assign(url);
    }

    return yield put(filesActions.generateAnnualPayrollSalaryReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateAnnualPayrollSalaryReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateAnnualPayrollSalaryReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateAnnualPayrollSalaryReportFail({
      error: {
        generateAnnualPayrollSalaryReportError,
      },
    }));
  }
}

function* generateUtilizationReport({ payload }) {
  try {
    const mutation = queryConfig.generateUtilizationReport;
    const options = {
      mutation,
      variables: { fields: payload },
    };

    const response = yield call(executeMutation, options);
    const url = get(response, 'generateUtilizationReport.url');
    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateUtilizationReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateUtilizationReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateUtilizationReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateUtilizationReportFail({
      error: {
        generateUtilizationReportError,
      },
    }));
  }
}

function* generateCurrentPayrollReport({ payload }) {
  try {
    const query = queryConfig.generateCurrentPayrollReport;
    const options = {
      query,
      variables: {
        fields: payload,
      },
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateCurrentPayrollReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateCurrentPayrollReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateCurrentPayrollReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateCurrentPayrollReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateCurrentPayrollReportFail({
      error: {
        generateCurrentPayrollReportError,
      },
    }));
  }
}

function* generateBookingReport({ payload }) {
  try {
    const query = queryConfig.generateBookingReport;
    const options = {
      query,
      variables: {
        fields: payload,
      },
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateBookingReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateBookingReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateBookingReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateBookingReportError = yield call(parseError, error, options);

    return yield put(filesActions.generateBookingReportFail({
      error: {
        generateBookingReportError,
      },
    }));
  }
}

function* generateCurrentPayrollExcelReport({ payload }) {
  try {
    const query = queryConfig.generateCurrentPayrollExcelReport;
    const options = {
      query,
      variables: {
        fields: payload,
      },
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateCurrentPayrollExcelReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateCurrentPayrollExcelReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateCurrentPayrollExcelReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateCurrentPayrollExcelReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateCurrentPayrollExcelReportFail({
      error: {
        generateCurrentPayrollExcelReportError,
      },
    }));
  }
}

function* generateMentoringCompensationExcelReport({ payload }) {
  try {
    const query = queryConfig.generateMentoringCompensationExcelReport;
    const options = {
      query,
      variables: payload,
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateMentoringCompensationReport.url', null);
    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateMentoringCompensationExcelReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateMentoringCompensationExcelReportError',
      [],
    );
    const options = {
      entityName,
      storedErrors,
    };
    const generateMentoringCompensationExcelReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateMentoringCompensationExcelReportFail({
      error: {
        generateMentoringCompensationExcelReportError,
      },
    }));
  }
}

function* generateStaffWorkLogsExcelReport({ payload }) {
  try {
    const query = queryConfig.generateStaffWorkLogsExelReport;
    const options = {
      query,
      variables: payload,
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'generateDevstaffWorklogsExcelReport.url', null);
    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateMentoringCompensationExcelReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateMentoringCompensationExcelReportError',
      [],
    );
    const options = {
      entityName,
      storedErrors,
    };
    const generateMentoringCompensationExcelReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateMentoringCompensationExcelReportFail({
      error: {
        generateMentoringCompensationExcelReportError,
      },
    }));
  }
}

function* generatePtoReport() {
  try {
    const mutation = queryConfig.generatePtoReport;
    const options = {
      mutation,
      variables: {
        year: moment().year(),
      },
    };
    const response = yield call(executeMutation, options);
    const url = get(response, 'generateExcelPtoReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generatePtoReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generatePtoReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generatePtoReportError = yield call(parseError, error, options);

    return yield put(filesActions.generatePtoReportFail({
      error: {
        generatePtoReportError,
      },
    }));
  }
}

function* generateProjectLeadCommissionReport() {
  try {
    const mutation = queryConfig.generateProjectLeadCommissionReport;
    const {
      selectedDate,
      ...reportData
    } = yield select(selectReportData);
    const options = {
      mutation,
      variables: {
        ...reportData,
        year: selectedDate.year(),
        month: selectedDate.month() + 1,
      },
    };
    const response = yield call(executeMutation, options);
    const url = get(response, 'generatePlCommissionReport.url');

    if (url) {
      window.location.assign(url);
    }

    return yield put(filesActions.generateProjectLeadCommissionReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateProjectLeadCommissionReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateProjectLeadCommissionReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateProjectLeadCommissionReportFail({
      error: {
        generateProjectLeadCommissionReportError,
      },
    }));
  }
}

function* generateProjectLeadCommissionPDFReport({ payload: { plName } }) {
  try {
    const query = queryConfig.generateProjectLeadCommissionPDFReport;
    const {
      selectedDate,
      ...reportData
    } = yield select(selectReportData);
    const options = {
      query,
      variables: {
        ...reportData,
        year: selectedDate.year(),
        month: selectedDate.month() + 1,
        plName,
      },
    };
    const response = yield call(executeQuery, options);
    const url = get(response, 'plCommissionPdfReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateProjectLeadCommissionPDFReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateProjectLeadCommissionPDFReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateProjectLeadCommissionPDFReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateProjectLeadCommissionPDFReportFail({
      error: {
        generateProjectLeadCommissionPDFReportError,
      },
    }));
  }
}

function* generateBillingProjectReport({ payload }) {
  try {
    const mutation = queryConfig.generateBillingProjectReport;
    const {
      selectedDate,
      ...reportData
    } = yield select(selectReportData);
    const taskOrderId = yield select(selectCurrentTaskOrder);
    const reportType = yield select(selectProjectReportType);
    const isChannelPartnerVersion = yield select(selectIsChannelPartnerVersion) && reportType === 'TO';
    const options = {
      mutation,
      variables: {
        ...reportData,
        taskOrderId,
        reportType,
        year: selectedDate.year(),
        month: selectedDate.month() + 1,
        ...(isChannelPartnerVersion && {
          channelPartnerReport: payload.channelPartnerReport,
        }),
      },
    };
    const response = yield call(executeMutation, options);
    const url = get(response, 'generateBillingReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateBillingProjectReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateBillingProjectReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateBillingProjectReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateBillingProjectReportFail({
      error: {
        generateBillingProjectReportError,
      },
    }));
  }
}

function* generateBillingProjectExcelReport({ payload }) {
  try {
    const mutation = queryConfig.generateBillingProjectExcelReport;
    const options = {
      mutation,
      variables: {
        ...payload,
      },
      context: {
        headers: {
          'X-Profile': 1,
        },
      },
    };
    const response = yield call(executeMutation, options);
    const url = get(response, 'generateSummaryReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateBillingProjectExcelReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateBillingProjectExcelReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateBillingProjectExcelReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateBillingProjectExcelReportFail({
      error: {
        generateBillingProjectExcelReportError,
      },
    }));
  }
}

function* generateBillingProjectToExcelReport({ payload }) {
  try {
    const mutation = queryConfig.generateBillingProjectToExcelReport;
    const { selectedDate } = yield select(selectReportData);
    const taskOrderId = yield select(selectCurrentTaskOrder);
    const isChannelPartnerVersion = yield select(selectIsChannelPartnerVersion);
    const options = {
      mutation,
      variables: {
        taskOrderId,
        year: selectedDate.year(),
        month: selectedDate.month() + 1,
        ...(isChannelPartnerVersion && {
          channelPartnerReport: payload.channelPartnerReport,
        }),
      },
    };
    const response = yield call(executeMutation, options);
    const url = get(response, 'generateBillingReportExcel.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateBillingProjectToExcelReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateBillingProjectToExcelReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateBillingProjectToExcelReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateBillingProjectToExcelReportFail({
      error: {
        generateBillingProjectToExcelReportError,
      },
    }));
  }
}

function* uploadFileSaga({
  // eslint-disable-line consistent-return
  payload: {
    clientId,
    projectId,
    channelPartnerId,
    fileName,
    fileType,
    title,
    file,
    effectiveDate,
    isSigned,
  },
  meta: { entityName },
}) {
  const actions = {
    CLIENTS: clientsActions,
    TASK_ORDERS: taskOrdersActions,
    CHANNEL_PARTNERS: channelPartnersActions,
  };
  const fields = {};
  switch (true) {
    case !isNil(clientId): {
      fields.clientId = {
        clientId,
        fileType,
        title,
        effectiveDate,
        isSigned,
        msaNumber: fileName,
      };
      break;
    }
    case !isNil(projectId): {
      fields.projectId = {
        projectId,
        title,
        effectiveDate,
        isSigned,
      };
      break;
    }
    case !isNil(channelPartnerId): {
      fields.channelPartnerId = {
        channelPartnerId,
        title,
        effectiveDate,
      };
      break;
    }
    default:
      return fields;
  }

  try {
    const { uploadFileError } = yield select(selectErrors);
    const requestURL = yield call(getRequestUrl, '/graphql');
    const accessToken = yield call(appStorage.getAccessToken);

    const variables = {
      clientFile: fields.clientId || {},
      taskOrderFile: fields.projectId || {},
      emailFile: {},
      channelPartnerFile: fields.channelPartnerId || {},
    };

    const body = queryConfig.uploadFile(variables);
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      data: { uploadFile },
      errors,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        containerId: entityName,
        errors,
        activeErrors: uploadFileError,
      });

      return yield put(filesActions.uploadFileFail({
        error: {
          uploadFileError: listErrors,
        },
      }));
    }

    const {
      file: { fileId },
      clientFileUploadData,
      taskOrderUploadData,
      channelPartnerFileUploadData,
    } = uploadFile;
    const response = clientFileUploadData || taskOrderUploadData || channelPartnerFileUploadData;
    const channel = yield call(createUploadFileChannel, response, file);

    while (true) {
      const {
        progress = 0,
        error,
        success,
      } = yield take(channel);

      if (error) {
        const { message } = error;

        const deleteBody = queryConfig.deleteFile({
          fileId,
        });
        const deleteOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: deleteBody,
        };
        yield call(request, requestURL, deleteOptions);

        getNotifications({
          containerId: entityName,
          error: message,
        });
        return yield put(filesActions.uploadFileFail({
          error: {
            uploadFileError: [message || 'unknown'],
          },
        }));
      }

      if (success) {
        if (uploadFileError) {
          toast.dismiss();
        }

        /* eslint-disable */
        const getActions = () => {
          if (actions[entityName].getUploadedFile) {
            return put(actions[entityName].getUploadedFile({
              fileId,
              entityName,
              clientId,
              channelPartnerId,
            }));
          }
        };
        /* eslint-enable */

        return yield all([
          put(filesActions.uploadFileSuccess()),
          getActions(),
        ]);
      }

      yield put(filesActions.updateUploadingProgress({
        progress: Math.round(progress * 100),
      }));
    }
  } catch (error) {
    const { message } = error;

    getNotifications({
      containerId: entityName,
      error: message,
    });
    return yield put(filesActions.uploadFileFail({
      error: {
        uploadFileError: [message || 'unknown'],
      },
    }));
  }
}

function* deleteDocument({ payload, meta }) {
  const { fileId } = payload;
  const { entityName, clientId, channelPartnerId } = meta;
  const {
    entityErrors,
    entityActions,
  } = yield call(getEntity, entityName);

  try {
    const { deleteDocumentError } = yield select(entityErrors);
    const requestURL = yield call(getRequestUrl, '/graphql');

    const variables = {
      fileId,
    };

    const accessToken = yield call(appStorage.getAccessToken);
    const body = queryConfig.deleteFile(variables);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const { errors } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        containerId: entityName,
        errors,
        activeErrors: deleteDocumentError,
      });

      return yield put(entityActions.deleteDocumentFail({
        error: {
          deleteDocumentError: listErrors,
        },
      }));
    }

    if (deleteDocumentError) {
      deleteDocumentError.forEach((error) => toast.dismiss(error));
    }

    return yield all([
      put(entityActions.deleteDocumentSuccess({
        fileId,
        channelPartnerId,
        ...(clientId && { clientId }),
      })),
      put(modalConductorActions.clearModal()),
    ]);
  } catch (error) {
    const { message } = error;

    getNotifications({
      containerId: entityName,
      error: message,
    });
    return yield put(entityActions.deleteDocumentFail({
      error: {
        deleteDocumentError: [message || 'unknown'],
      },
    }));
  }
}

function* generateDocumentSaga({
  payload,
  meta: { entityName },
}) {
  const {
    entityErrors,
    entityActions,
  } = yield call(getEntity, entityName);

  try {
    const { generateDocumentError } = yield select(entityErrors);

    const requestURL = yield call(getRequestUrl, '/graphql');
    const accessToken = yield call(appStorage.getAccessToken);
    const { variables } = yield call(getGeneratorEntity, payload, entityName);

    const body = queryConfig.generateFile(variables);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const {
      errors,
      data,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        containerId: entityName,
        errors,
        activeErrors: generateDocumentError,
      });

      return yield put(entityActions.generateDocumentFail({
        error: {
          generateDocumentError: listErrors,
        },
      }));
    }

    if (generateDocumentError) {
      generateDocumentError.forEach((error) => toast.dismiss(error));
    }

    const {
      generateFile: { fileId },
    } = data;

    return yield all([
      put(
        entityActions.getUploadedFile({
          fileId,
          entityName,
          clientId: payload.clientId,
        }),
      ),
      put(modalConductorActions.clearModal()),
    ]);
  } catch (error) {
    const { message } = error;

    getNotifications({
      containerId: entityName,
      error: message,
    });
    return yield put(entityActions.generateDocumentFail({
      error: {
        generateDocumentError: [message || 'unknown'],
      },
    }));
  }
}

function* signDocumentSaga({
  payload: {
    fileId,
    pocEmail,
    pocName,
    message,
  },
  meta: { entityName },
}) {
  const {
    entityErrors,
    entityActions,
  } = yield call(getEntity, entityName);

  try {
    const { signDocumentError } = yield select(entityErrors);
    const requestURL = yield call(getRequestUrl, '/graphql');
    const accessToken = yield call(appStorage.getAccessToken);

    const variables = {
      fields: {
        fileId,
        pocEmail,
        pocName,
        message,
      },
    };

    const body = queryConfig.signDocument(variables);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    const { errors } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        containerId: 'modal',
        errors,
        activeErrors: signDocumentError,
      });

      return yield put(entityActions.signDocumentFail({
        containerId: 'modal',
        error: {
          signDocumentError: listErrors,
        },
      }));
    }

    if (signDocumentError) {
      signDocumentError.forEach((error) => toast.dismiss(error));
    }

    return yield all([
      put(entityActions.signDocumentSuccess({
        fileId,
      })),
      put(modalConductorActions.clearModal()),
    ]);
  } catch (error) {
    const { message: errorMessage } = error;

    getNotifications({
      containerId: 'modal',
      error: errorMessage,
    });

    return yield put(entityActions.signDocumentFail({
      error: {
        signDocumentError: [message || 'unknown'],
      },
    }));
  }
}

function* getUploadedFileSaga({
  payload: { fileId },
  meta: {
    entityName,
    clientId,
    channelPartnerId,
  },
}) {
  const {
    entityErrors,
    selectRequestsCounter,
    entityActions,
  } = yield call(
    getFileLoaderEntity,
    entityName,
  );

  try {
    const { getUploadedFileError } = yield select(entityErrors);
    const requestsCounter = yield select(selectRequestsCounter);

    if (requestsCounter > 5) {
      getNotifications({
        containerId: entityName,
        error: 'Error: file upload failed, please, try again',
      });

      return yield put(clientsActions.getUploadedFileFail({
        error: {
          getUploadedFileError: ['unknown'],
        },
      }));
    }

    const requestURL = yield call(getRequestUrl, '/graphql');
    const query = queryConfig.getUploadedFile(fileId);
    const accessToken = yield call(appStorage.getAccessToken);

    const body = JSON.stringify({
      query,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    };

    yield delay(1500);

    const {
      errors,
      data,
    } = yield call(request, requestURL, options);

    if (errors) {
      const listErrors = getNotifications({
        containerId: entityName,
        errors,
        activeErrors: getUploadedFileError,
      });

      return yield put(entityActions.getUploadedFileFail({
        error: {
          getUploadedFileError: listErrors,
        },
      }));
    }

    if (getUploadedFileError) {
      getUploadedFileError.forEach((error) => toast.dismiss(error));
    }

    if (!data || !data.node || !data.node || !data.node.downloadUrl) {
      return yield all([
        put(entityActions.getUploadedFile({
          fileId,
          entityName,
        })),
      ]);
    }

    return yield put(entityActions.getUploadedFileSuccess({
      file: data.node,
      ...(clientId && { clientId }),
      ...(channelPartnerId && { channelPartnerId }),
    }));
  } catch (error) {
    const { message } = error;

    getNotifications({
      containerId: entityName,
      error: message,
    });

    return yield put(entityActions.getUploadedFileFail({
      error: {
        getUploadedFileError: [message || 'unknown'],
      },
    }));
  }
}

function* generateTodayBillingReport({ payload }) {
  try {
    const mutation = queryConfig.generateTodayBillingReport;
    const options = {
      mutation,
      variables: {
        ...payload,
      },
    };
    const response = yield call(executeMutation, options);
    const url = get(response, 'todayBillingReport.url');

    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateTodayBillingReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateTodayBillingReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateTodayBillingReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateTodayBillingReportFail({
      error: {
        generateTodayBillingReportError,
      },
    }));
  }
}

function* generateUtilizationForMonthReport({ payload }) {
  try {
    const mutation = queryConfig.generateUtilizationForMonthReport;
    const options = {
      mutation,
      variables: { fields: payload },
    };

    const response = yield call(executeMutation, options);
    const url = get(response, 'generateUtilizationForMonthReport.url');
    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateUtilizationForMonthReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateUtilizationForMonthReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateUtilizationForMonthReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateUtilizationForMonthReportFail({
      error: {
        generateUtilizationForMonthReportError,
      },
    }));
  }
}

function* generateUtilizationForYearReport({ payload }) {
  try {
    const mutation = queryConfig.generateUtilizationForYearReport;
    const options = {
      mutation,
      variables: { fields: payload },
    };

    const response = yield call(executeMutation, options);
    const url = get(response, 'generateUtilizationForYearReport.url');
    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateUtilizationForYearReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(
      errors,
      'generateUtilizationForYearReportError',
      [],
    );

    const options = {
      entityName,
      storedErrors,
    };
    const generateUtilizationForYearReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateUtilizationForYearReportFail({
      error: {
        generateUtilizationForYearReportError,
      },
    }));
  }
}

function* generateInternalToExcelReport({
  payload: {
    month,
    year,
    fields,
  },
}) {
  try {
    const mutation = queryConfig.generateInternalToExcelReport;
    const options = {
      mutation,
      variables: {
        month,
        year,
        fields,
      },
    };

    const response = yield call(executeMutation, options);
    const url = get(response, 'generateInternalToReport.url');
    if (url) {
      window.open(url, '_blank');
    }

    return yield put(filesActions.generateInternalToExcelReportSuccess());
  } catch (error) {
    const entityName = yield select(selectCurrentEntityName);
    const errors = yield select(selectErrors);
    const storedErrors = get(errors, 'generateInternalToExcelReportError', []);

    const options = {
      entityName,
      storedErrors,
    };
    const generateInternalToExcelReportError = yield call(
      parseError,
      error,
      options,
    );

    return yield put(filesActions.generateInternalToExcelReportFail({
      error: {
        generateInternalToExcelReportError,
      },
    }));
  }
}

function* uploadFileSagaWatcher() {
  yield takeEvery(
    filesActionsTypes.UPLOAD_FILE,
    waitForAuthorization(uploadFileSaga),
  );
}

function* deleteDocumentSagaWatcher() {
  yield takeEvery(
    [
      clientsActionsTypes.DELETE_DOCUMENT,
      taskOrdersActionsTypes.DELETE_DOCUMENT,
      channelPartnersActionsTypes.DELETE_DOCUMENT,
    ],
    waitForAuthorization(deleteDocument),
  );
}

function* generateDocumentSagaWatcher() {
  yield takeEvery(
    [
      clientsActionsTypes.GENERATE_DOCUMENT,
      taskOrdersActionsTypes.GENERATE_DOCUMENT,
    ],
    waitForAuthorization(generateDocumentSaga),
  );
}

function* getUploadedFileSagaWatcher() {
  yield takeEvery(
    [
      clientsActionsTypes.GET_UPLOADED_FILE,
      taskOrdersActionsTypes.GET_UPLOADED_FILE,
      channelPartnersActionsTypes.GET_UPLOADED_FILE,
    ],
    waitForAuthorization(getUploadedFileSaga),
  );
}

function* signDocumentSagaWatcher() {
  yield takeEvery(
    filesActionsTypes.SIGN_DOCUMENT,
    waitForAuthorization(signDocumentSaga),
  );
}

function* generateUtilizationReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_UTILIZATION_REPORT,
    waitForAuthorization(generateUtilizationReport),
  );
}

function* generateUtilizationForMonthReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_UTILIZATION_FOR_MONTH_REPORT,
    waitForAuthorization(generateUtilizationForMonthReport),
  );
}

function* generateUtilizationForYearReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_UTILIZATION_FOR_YEAR_REPORT,
    waitForAuthorization(generateUtilizationForYearReport),
  );
}

function* generateBookingReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_BOOKING_REPORT,
    waitForAuthorization(generateBookingReport),
  );
}

function* generateCurrentPayrollReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_CURRENT_PAYROLL_REPORT,
    waitForAuthorization(generateCurrentPayrollReport),
  );
}

function* generateCurrentPayrollExcelReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_CURRENT_PAYROLL_EXCEL_REPORT,
    waitForAuthorization(generateCurrentPayrollExcelReport),
  );
}

function* generateProjectLeadCommissionReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_PROJECT_LEAD_COMMISSION_REPORT,
    waitForAuthorization(generateProjectLeadCommissionReport),
  );
}

function* generateProjectLeadCommissionPDFReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_PROJECT_LEAD_COMMISSION_PDF_REPORT,
    waitForAuthorization(generateProjectLeadCommissionPDFReport),
  );
}

function* generateContractFulfillmentPDFReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_CONTRACT_FULFILLMENT_PDF_REPORT,
    waitForAuthorization(generateContractFulfillmentPDFReport),
  );
}

function* generatePtoReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_PTO_REPORT,
    waitForAuthorization(generatePtoReport),
  );
}

function* generateBillingProjectReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_BILLING_PROJECT_REPORT,
    waitForAuthorization(generateBillingProjectReport),
  );
}

function* generateBillingProjectExcelReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_BILLING_PROJECT_EXCEL_REPORT,
    waitForAuthorization(generateBillingProjectExcelReport),
  );
}

function* generateBillingProjectToExcelReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_BILLING_PROJECT_TO_EXCEL_REPORT,
    waitForAuthorization(generateBillingProjectToExcelReport),
  );
}

function* generateReconciliationReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_RECONCILIATION_REPORT,
    waitForAuthorization(generateReconciliationReport),
  );
}

function* generateReconciliationSecondReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_RECONCILIATION_SECOND_REPORT,
    waitForAuthorization(generateReconciliationSecondReport),
  );
}

function* generateAnnualPayrollReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_REPORT,
    waitForAuthorization(generateAnnualPayrollReport),
  );
}

function* generateAnnualPayrollExcelReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_EXCEL_REPORT,
    waitForAuthorization(generateAnnualPayrollExcelReport),
  );
}

function* generateAnnualPayrollSalaryReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_ANNUAL_PAYROLL_SALARY_REPORT,
    waitForAuthorization(generateAnnualPayrollSalaryReport),
  );
}

function* generateTodayBillingReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_TODAY_BILLING_REPORT,
    waitForAuthorization(generateTodayBillingReport),
  );
}

function* generateTInternalToExcelReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_INTERNAL_TO_EXCEL_REPORT,
    waitForAuthorization(generateInternalToExcelReport),
  );
}

function* generateMentoringCompensationExcelReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_MENTORING_COMPENSATION_EXCEL_REPORT,
    waitForAuthorization(generateMentoringCompensationExcelReport),
  );
}

function* generateStaffWorkLogsExcelReportWatcher() {
  yield takeEvery(
    filesActionsTypes.GENERATE_STAFF_WORKLOGS_REPORT,
    waitForAuthorization(generateStaffWorkLogsExcelReport),
  );
}

export default [
  uploadFileSagaWatcher,
  signDocumentSagaWatcher,
  generatePtoReportWatcher,
  deleteDocumentSagaWatcher,
  getUploadedFileSagaWatcher,
  generateDocumentSagaWatcher,
  generateUtilizationReportWatcher,
  generateUtilizationForMonthReportWatcher,
  generateUtilizationForYearReportWatcher,
  generateBookingReportWatcher,
  generateAnnualPayrollReportWatcher,
  generateAnnualPayrollExcelReportWatcher,
  generateAnnualPayrollSalaryReportWatcher,
  generateCurrentPayrollReportWatcher,
  generateCurrentPayrollExcelReportWatcher,
  generateBillingProjectReportWatcher,
  generateBillingProjectExcelReportWatcher,
  generateBillingProjectToExcelReportWatcher,
  generateProjectLeadCommissionReportWatcher,
  generateProjectLeadCommissionPDFReportWatcher,
  generateReconciliationReportWatcher,
  generateReconciliationSecondReportWatcher,
  generateTodayBillingReportWatcher,
  generateTInternalToExcelReportWatcher,
  generateMentoringCompensationExcelReportWatcher,
  generateContractFulfillmentPDFReportWatcher,
  generateStaffWorkLogsExcelReportWatcher,
];
