import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as IconSort } from 'assets/icons/sort.svg';
import { ReactComponent as IconCalendar } from 'assets/img/icon-calendar2.svg';
import { ReactComponent as IconUser } from 'assets/img/icon-user.svg';
import classNames from 'classnames';
import { ActionButton } from 'components/buttons';
import GroupController from 'components/expansion-panel/group-control-button';
import FilterSelect from 'components/filter-select';
import Controls from 'components/list-controls';
import Loader from 'components/loader';
import ToastContainer from 'components/toast';
import EmptyMessage from 'elements/empty-message';
import Wrapper from 'elements/wrapper';
import { isEmpty, get, isNull } from 'lodash';
import moment from 'moment';

import { RangeFilter } from './components/range-filter/range-filter';
import { ReportFilter } from './components/report-filter/report-filter';
import { ReportTable } from './components/report-table/report-table';
import { TEXTS } from './locales/en';
import styles from './style.module.scss';
import { calculateTime, calculateTotalTime, transformData } from './utils';

export const StaffWorkLogsReport = ({
  getClientList,
  clientList,
  staffWorkLogsClientsFetching,
  getStaffWorkLogsReport,
  staffWorkLogsReport,
  isFetching,
  filter,
  changeFilter,
  generateExel,
  entityName,
  clearFilter,
  clearData,
}) => {
  const [renderedData, setRenderedData] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);
  const [isExcelDisabled, setIsExcelDisabled] = useState(true);
  const [type, setType] = useState('Employee');
  const [dateRange, setDateRange] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const { clientCategories, internalCategories, search } = filter;

  const onClientChange = useCallback((value) => {
    setCurrentClient(value.value);
    clearFilter();
  }, []);

  const onFilterChange = useCallback((filterValue) => {
    const key = filterValue.storeKey;
    changeFilter({
      storeKey: key,
      selected: renderedData[key] ? renderedData[key].filter((item) => filterValue.selected.includes(item.name)) : filterValue.selected,
    });
  }, [renderedData]);

  const onSetType = useCallback((valueType) => {
    setSortDirection(() => valueType === 'Employee' ? 'asc' : 'desc');
    setType(valueType);
  }, [type]);

  const toggleSortDirection = useCallback(() => {
    setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  }, []);

  const generateReport = useCallback(() => {
    const [dateStart, dateEnd] = dateRange;
    if (!isNull(currentClient) && dateStart && dateEnd) {
      const formattedStartDate = moment(dateStart, 'ddd MMM DD YYYY HH:mm:ss GMTZ', true)
        .local()
        .format('YYYY-MM-DD');

      const formattedEndDate = moment(dateEnd, 'ddd MMM DD YYYY HH:mm:ss GMTZ', true)
        .local()
        .format('YYYY-MM-DD');

      generateExel({
        clientId: currentClient,
        dateStart: formattedStartDate,
        endDate: formattedEndDate,
        clientCategories: clientCategories.map((item) => item.id),
        internalCategories: internalCategories.map((item) => item.id),
        sortedByDate: type === 'Date',
      });
    }
  }, [currentClient, dateRange, clientCategories, internalCategories, type]);

  useEffect(() => {
    const [dateStart, dateEnd] = dateRange;
    if (!isNull(currentClient) && dateStart && dateEnd) {
      const formattedStartDate = moment(dateStart, 'ddd MMM DD YYYY HH:mm:ss GMTZ', true)
        .local()
        .format('YYYY-MM-DD');

      const formattedEndDate = moment(dateEnd, 'ddd MMM DD YYYY HH:mm:ss GMTZ', true)
        .local()
        .format('YYYY-MM-DD');

      getStaffWorkLogsReport({
        clientId: currentClient,
        dateStart: formattedStartDate,
        endDate: formattedEndDate,
        clientCategories: clientCategories.map((item) => item.id),
        internalCategories: internalCategories.map((item) => item.id),
      });
    }
  }, [currentClient, dateRange, clientCategories, internalCategories]);

  useEffect(() => {
    setRenderedData(staffWorkLogsReport);
  }, [staffWorkLogsReport]);

  useEffect(() => {
    if (isEmpty(clientList)) {
      getClientList();
    }
    return () => {
      clearFilter();
      clearData();
    };
  }, []);

  const sortResult = useCallback((data) => data.sort((a, b) => {
    const nameA = a.resFullname || moment(a.logDate).format('YYYY/MM/DD');
    const nameB = b.resFullname || moment(b.logDate).format('YYYY/MM/DD');
    if (sortDirection === 'asc') {
      return nameA.localeCompare(nameB);
    }
    return nameB.localeCompare(nameA);
  }), [sortDirection]);

  const filteredItems = get(renderedData, 'items', []).filter((item) => item.resFullname.toLowerCase().includes(search.toLowerCase()));
  const result = transformData(filteredItems, type);
  const sortedResult = sortResult(result);

  const totals = useMemo(() => calculateTotalTime({
    totalHours: get(renderedData, 'totalHours', 0),
    totalMinutes: get(renderedData, 'totalMinutes', 0),
  }), [renderedData]);

  useEffect(() => {
    setIsExcelDisabled(() => isEmpty(sortedResult) || isFetching || isNull(currentClient));
  }, [isFetching, currentClient, sortedResult]);

  const render = () => {
    if (isNull(currentClient)) {
      return <EmptyMessage description={TEXTS.emptyClients} />;
    }

    return isEmpty(sortedResult) ? (
      <EmptyMessage description={TEXTS.noLogs} />
    ) : (
      <>
        <div className={styles.tableControls}>
          <ActionButton
            dataId="staffWorkLogsSorting"
            onClick={toggleSortDirection}
            className={classNames(styles.sort, styles[type], styles[sortDirection])}
          >
            {type}
            <IconSort />
          </ActionButton>

          <GroupController
            groupId="staffWorkLog"
            expandSettings={{
              defaultExpand: false,
            }}
            className={styles.groupControl}
          />
        </div>
        {sortedResult.map((item) => (
          <ReportTable
            key={item.resId || item.logDateId}
            data={item.worklogs}
            totals={calculateTime(item.worklogs)}
            label={item.resFullname || moment(item.logDate).format('MM/DD/YYYY')}
            isInternal={isEmpty(renderedData.clientCategories.filter((clientCategory) => clientCategory.id !== 0))}
            type={type}
          />
        ))}
        <div className={styles.footer}>
          <ul>
            <li>{TEXTS.total}:</li>
            <li>{totals.totalHours}h {totals.totalMinutes}m</li>
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <ToastContainer containerId={entityName} />
      <Controls
        title={TEXTS.controlsTitle}
        className={styles.controls}
      >
        <FilterSelect
          key="clientSelect"
          items={clientList}
          isFetchingData={staffWorkLogsClientsFetching}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          onChange={onClientChange}
          placeholder={TEXTS.selectClient}
          placeholderLength="12.5rem"
          withChips={false}
        />
        <RangeFilter range={dateRange} setDateRange={setDateRange} />
        <div className={styles.buttonGroup}>
          <ActionButton
            dataId="Employee"
            onClick={() => onSetType('Employee')}
            className={classNames(styles.toggleButton, {
              [styles.active]: type === 'Employee',
            })}
          >
            <IconUser />
          </ActionButton>
          <ActionButton
            dataId="Date"
            onClick={() => onSetType('Date')}
            className={classNames(styles.toggleButton, {
              [styles.active]: type === 'Date',
            })}
          >
            <IconCalendar />
          </ActionButton>
          <ActionButton
            dataId="Excel"
            onClick={generateReport}
            className={classNames(styles.excelButton)}
            disabled={isExcelDisabled}
          >
            Excel
          </ActionButton>
        </div>
      </Controls>
      <Wrapper isGrow className={styles.wrapper}>
        {
          !isNull(renderedData) && !isEmpty(renderedData) ? (
            <ReportFilter
              isInternal={isEmpty(renderedData.clientCategories.filter((clientCategory) => clientCategory.id !== 0))}
              internalCategories={get(renderedData, 'internalCategories', [])}
              clientCategories={get(renderedData, 'clientCategories', [])}
              onChange={onFilterChange}
              selected={filter}
            />
          ) : null
        }
        {
          isFetching ? <Loader /> : render()
        }
      </Wrapper>
    </>
  );
};

StaffWorkLogsReport.propTypes = {
  getClientList: PropTypes.func.isRequired,
  clientList: PropTypes.array.isRequired,
  staffWorkLogsClientsFetching: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  getStaffWorkLogsReport: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  staffWorkLogsReport: PropTypes.object.isRequired,
  generateExel: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  clearFilter: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
};
