/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import {
  connectRouter,
} from 'connected-react-router';
import {
  combineReducers,
} from 'redux';

import authReducer from './auth/reducer';
import channelPartnersReducer from './channel-partners/reducer';
import clientsReducer from './clients/reducer';
import commonReducer from './common/reducer';
import categoryManagementReducer from './configurations/category-management/reducer';
import userManagementReducer from './configurations/user-management/reducer';
import dashboardReducer from './dashboard/reducer';
import deliveryReducer from './delivery/reducer';
import deliveryCenterUtilizationByMonthReducer from './delivery-center-utilization-by-month/reducer';
import uploadingReducer from './files/reducer';
import modalConductorReducer from './modal-conductor/reducer';
import reconciliationReducer from './reconciliation/reducer';
import requiredReviewsReportReducer from './required-reviews-report/reducer';
import staffWorklogsReportReducer from './staff-worklogs-report/reducer';
import resourceManagementReportReducer from './resource-management-report/reducer';
import taskOrdersReducer from './task-orders/reducer';
import userReducer from './user/reducer';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */

export default function createCombineReducer(history, injectedReducers) {
  return combineReducers({
    router: connectRouter(history),
    modal: modalConductorReducer,
    clients: clientsReducer,
    channelPartners: channelPartnersReducer,
    auth: authReducer,
    user: userReducer,
    taskOrders: taskOrdersReducer,
    uploading: uploadingReducer,
    delivery: deliveryReducer,
    dashboard: dashboardReducer,
    reconciliation: reconciliationReducer,
    deliveryCenterUtilizationByMonth: deliveryCenterUtilizationByMonthReducer,
    common: commonReducer,
    categories: categoryManagementReducer,
    resourceManagementReport: resourceManagementReportReducer,
    requiredReviewsReport: requiredReviewsReportReducer,
    staffWorkLogsReport: staffWorklogsReportReducer,
    userManagement: userManagementReducer,
    ...injectedReducers,
  });
}
