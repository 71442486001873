import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Calendar from 'components/calendar';

import Counter from 'components/counter';
import FilterSelect from 'components/filter-select';

import Controls from 'components/list-controls';
import { confRateChangeNotificationsAccess } from 'core/auth/guaranteedAccessRoles';
import { selectUserGroup } from 'core/auth/selectors';
import { clientRateCardUpdateNotifications } from 'core/configurations/client-rate-card-update-notifications/actions';
import {
  selectRateCardNotificationsVersion,
  selectRateCardNotificationsVersionsList,
  selectRateCardNotifications,
  selectBillingAgentIds,
  selectRateCardsForRatecardNotifications,
  selectFormData,
  selectCounter,
  selectNotificationTypes,
  selectIsSendingEmailsActive,
} from 'core/configurations/client-rate-card-update-notifications/selectors';
import { selectIsFetching } from 'core/configurations/selectors';

import { modalConductorActions } from 'core/modal-conductor/actions';
import StyledWrapper from 'elements/styled-wrapper';
import Wrapper from 'elements/wrapper';
import RateChangeNotificationForm from 'forms/email-notifications-forms/rate-change-notifications-form';
import { useEffectOnlyOnUpdate } from 'hooks';

import { TITLE, DEFAULT_COUNTER } from 'layouts/rate-change-notifications/constants';

import { has } from 'lodash';
import moment from 'moment';
import { getHasPermissions } from 'utils/auth';

import { format as formatDate, FORMATS } from 'utils/date';
import {
  sortBy,
  calendarStepControlsConfig,
  calendarPopperProps,
  mergeItemsById,
} from 'utils/notifications';

import {
  yearPickerCssRules,
  calendarItemCssRules,
  controlsCssRules,
  rateChangeNotificationsWrapperStyles,
  filterSelectCssRules,
  addButtonStyles,
} from './styles';

import styles from './styles.module.scss';
import { useListColumns } from './useListColumns';

const {
  checkRateCardNotificationsStatus,
  updateRateCardNotifications,
  createRatecardNotificationsVersion,
  sendRatecardNotificationsEmails,
  clearRatecardNotifications,
  deleteRatecardNotificationsVersion,
  stopCheckRateCardNotificationsStatus,
} = clientRateCardUpdateNotifications;

const RateChangeNotifications = () => {
  const billingAgentIds = useSelector(selectBillingAgentIds);
  const rateCards = useSelector(selectRateCardsForRatecardNotifications);
  const notificationTypes = useSelector(selectNotificationTypes);
  const userGroup = useSelector(selectUserGroup);
  const version = useSelector(selectRateCardNotificationsVersion);
  const rateCardNotificationsVersions = useSelector(selectRateCardNotificationsVersionsList);
  const data = useSelector(selectFormData);
  const rateCardNotifications = useSelector(selectRateCardNotifications);
  const counter = useSelector(selectCounter);
  const isFetching = useSelector(selectIsFetching);
  const isSendingEmailsActive = useSelector(selectIsSendingEmailsActive);
  const dispatch = useDispatch();
  const isDeleteVersionEnabled = rateCardNotificationsVersions.length > 1 && (version && version.allowedForDelete);

  const isEditable = getHasPermissions(userGroup, confRateChangeNotificationsAccess.accessToEditRecords);
  const isControlsHidden = !getHasPermissions(userGroup, confRateChangeNotificationsAccess.accessToControlButtons);

  const [date, setDate] = useState(moment());

  const onChangeVersion = useCallback((selected) => {
    if (selected !== version.versionId) {
      dispatch(checkRateCardNotificationsStatus({
        year: moment(date).year(),
        version: selected.value,
      }));
    }
  }, [date, version, isSendingEmailsActive]);

  const changeYear = useCallback((calendarValue) => {
    setDate(calendarValue);
  }, []);

  const onSubmitHandler = (formData) => {
    if (!formData) return;

    const notificationsData = mergeItemsById({
      initialListItems: rateCardNotifications,
      listItems: formData,
    });

    dispatch(updateRateCardNotifications({
      fields: {
        year: moment(date).year(),
        version: version.versionId,
        notificationsData,
      },
    }));
  };

  const sendEmailsHandler = () => {
    dispatch(sendRatecardNotificationsEmails({
      year: moment(date).year(),
      version: version.versionId,
    }));
  };

  const deleteVersionHandler = useCallback(() => {
    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: 'Delete this version?',
          },
          {
            type: 'description',
            data: 'Once deleted, it cannot be restored.',
          },
        ],
        acceptActionName: 'deleteVersion',
        acceptActionTitle: 'Delete',
        acceptFunction: () => dispatch(deleteRatecardNotificationsVersion({
          version: version.versionId,
          year: moment(date).year(),
        })),
      },
    }));
  }, [version, date]);

  const addVersionHandler = useCallback(() => {
    dispatch(createRatecardNotificationsVersion({
      fields: {
        year: moment(date).year(),
      },
    }));
  }, [date]);

  const controlsList = useMemo(() => [
    {
      type: 'action',
      data: 'Delete Version',
      onClick: deleteVersionHandler,
      cssRules: `
              ${addButtonStyles}
            `,
      disabled: !isDeleteVersionEnabled,
      isHidden: isControlsHidden,
    },
    {
      type: 'action',
      data: 'Add Version',
      onClick: addVersionHandler,
      cssRules: `
                ${addButtonStyles}
              `,
      isHidden: isControlsHidden,
    },
  ], [version, isDeleteVersionEnabled]);

  const selected = useMemo(() => {
    const isVersionExist = has(version, 'versionId') && !isFetching;

    return isVersionExist ? {
      value: version.versionId,
      label: `Version ${version.versionId}`,
    } : undefined;
  }, [version]);

  const columns = useListColumns({
    billingAgentIds,
    rateCards,
    notificationTypes,
    isEditable,
  });

  useEffect(() => {
    dispatch(checkRateCardNotificationsStatus({
      year: moment(date).year(),
    }));

    return () => {
      if (isSendingEmailsActive) {
        dispatch(stopCheckRateCardNotificationsStatus());
      }

      dispatch(clearRatecardNotifications());
    };
  }, []);

  useEffectOnlyOnUpdate(() => {
    dispatch(checkRateCardNotificationsStatus({
      year: moment(date).year(),
    }));
  }, [date]);

  return (
    <div data-id="layout">
      <Controls
        title={TITLE}
        cssRules={controlsCssRules}
        controlsList={controlsList}
      >
        <Calendar
          withStepControls
          withYearSelecting
          minDetails="decade"
          onChange={changeYear}
          currentDate={moment()}
          maxYear={moment().year() + 1}
          value={date}
          popperProps={calendarPopperProps}
          cssRules={yearPickerCssRules}
          stepControlsConfig={calendarStepControlsConfig}
        >
          <StyledWrapper cssRules={calendarItemCssRules}>
            {formatDate(date, FORMATS.YYYY)}
          </StyledWrapper>
        </Calendar>
        <FilterSelect
          cssRules={filterSelectCssRules}
          withChips={false}
          items={rateCardNotificationsVersions}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          onChange={onChangeVersion}
          placeholder="Version"
          isFetchingData={isFetching}
          placeholderLength="5rem"
          selected={selected}
        />
      </Controls>
      <Wrapper cssRules={rateChangeNotificationsWrapperStyles}>
        <RateChangeNotificationForm
          data={data}
          isFetching={isFetching}
          defaultCounter={DEFAULT_COUNTER}
          columns={columns}
          onSubmitHandler={onSubmitHandler}
          sendEmailsHandler={sendEmailsHandler}
          sortBy={sortBy}
          isEditable={isEditable}
          isControlsHidden={isControlsHidden}
          billingAgentIds={billingAgentIds}
          rateCards={rateCards}
          notificationTypes={notificationTypes}
        />
      </Wrapper>
      <Counter
        data={counter}
        className={styles.counter}
        contentClassName={styles.counterContent}
      />
    </div>
  );
};

export default RateChangeNotifications;
