import React, { forwardRef, useCallback, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ArrowDoubleLeft } from 'assets/img/icon-arrow-double-left.svg';
import { ReactComponent as ArrowDoubleRight } from 'assets/img/icon-arrow-double-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/icon-arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/img/icon-arrow-right.svg';
import { ReactComponent as IconCalendar } from 'assets/img/icon-calendar.svg';
import { ReactComponent as IconClose } from 'assets/img/icon-close.svg';

import classNames from 'classnames';
import ActionButton from 'elements/action-button';
import moment from 'moment';

import DatePicker, { CalendarContainer } from 'react-datepicker';

import styles from './styles.module.scss';

import 'react-datepicker/dist/react-datepicker.css';
import { isNull } from 'lodash';

export const RangePicker = ({
  name,
  dateStart,
  dateEnd,
  onChange,
  onOpen,
  onClose,
  placeholder,
  label,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const datepickerRef = useRef(null);

  const handleClear = useCallback(() => onChange([null, null]), []);

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (dateStart && isNull(dateEnd)) {
      onChange([dateStart, moment().toDate()]);
    }
    if (onClose) {
      onClose();
    }
  }, [dateStart, dateEnd]);

  // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
  const Input = forwardRef(({ value, onClick, ...rest }, ref) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <label htmlFor={name} className={styles.label} onClick={onClick} ref={ref}>
      <input
        data-id={name}
        id={name}
        type="text"
        onChange={rest.onChange}
        value={typeof value === 'string' ? value.replace(/ - /g, ' — ') : ''} /* eslint-disable-line react/prop-types */
        placeholder={placeholder}
      />
      {value && <ActionButton onClick={handleClear} data={<IconClose />} />}
      <IconCalendar />
    </label>
  ));

  const renderCustomHeader = ({
    decreaseMonth,
    decreaseYear,
    increaseMonth,
    increaseYear,
    monthDate,
  }) => (
    <div className={styles.header}>
      <div>
        <ActionButton onClick={decreaseYear} data={<ArrowDoubleLeft />} />
        <ActionButton onClick={decreaseMonth} data={<ArrowLeft />} />
      </div>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
      </span>
      <div>
        <ActionButton onClick={increaseMonth} data={<ArrowRight />} />
        <ActionButton onClick={increaseYear} data={<ArrowDoubleRight />} />
      </div>
    </div>
  );

  return (
    <div
      data-id="range-picker-field"
      className={classNames(styles.calendar, className, { [styles.calendarGrid]: label })}
      data-open={open || dateStart}
    >
      {label && <p>{label}</p>}
      <DatePicker
        ref={datepickerRef}
        selected={dateStart}
        onChange={onChange}
        startDate={dateStart}
        endDate={dateEnd}
        renderCustomHeader={renderCustomHeader}
        calendarContainer={({ children }) => (/* eslint-disable-line react/no-unstable-nested-components */
          <CalendarContainer className={styles.container}>{children}</CalendarContainer>
        )}
        customInput={<Input />}
        selectsRange
        monthsShown={2}
        maxDate={moment().toDate()}
        popperClassName={styles.popper}
        onCalendarOpen={handleOpen}
        onCalendarClose={handleClose}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        focusSelectedMonth
        dateFormat="MM/dd/yyyy"
      />
    </div>
  );
};
RangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dateStart: PropTypes.instanceOf(Date) || null,
  dateEnd: PropTypes.instanceOf(Date) || null,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func || null,
  onClose: PropTypes.func || null,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

RangePicker.defaultProps = {
  dateStart: null,
  dateEnd: null,
  onOpen: null,
  onClose: null,
  className: '',
  placeholder: '. . / . . / . . . .  —  . . /. . / . . . .',
};
