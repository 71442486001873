import gql from 'graphql-tag';

export const queries = {
  getClients: gql`{
    devstaffWorklogsReportClientsList {
      clients {
        clientId
        clientName
      }
    }
  }`,
  getStaffWorkLogs: gql`
    query devstaffWorklogsReport(
      $dateStart: Date!
      $endDate: Date!
      $clientId: Int!
      $clientCategories: [Int]
      $internalCategories: [Int]
    ) {
    devstaffWorklogsReport(
      dateStart: $dateStart
      endDate: $endDate
      clientId: $clientId
      clientCategories: $clientCategories
      internalCategories: $internalCategories
    ) {
      client
      clientId
      totalHours
      totalMinutes
      clientCategories {
        id
        name
      }
      internalCategories {
        id
        name
      }
      items {
        resName
        resFullname
        resId
        worklogs {
          logDate
          clientCategory
          clientCategoryId
          internalCategory
          internalCategoryId
          workLogs {
            body
            time
            hoursLogged
            minutesLogged
          }
        }
      }
    }
  }`,
};
