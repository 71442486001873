import React, { memo, useEffect } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { RangePicker } from 'components/rangePicker/RangePicker';
import ActionButton from 'elements/action-button';
import { isNull } from 'lodash';
import moment from 'moment';

import styles from './style.module.scss';

export const RangeFilter = memo(({ range, setDateRange }) => {
  const [startDate, endDate, type] = range;

  const handleMonth = () => {
    setDateRange([
      moment().startOf('month').toDate(),
      moment().toDate(),
      'month',
    ]);
  };

  const handleLastWeek = () => {
    setDateRange([
      moment().subtract(6, 'days').toDate(),
      moment().toDate(),
      'prevWeek',
    ]);
  };

  useEffect(() => {
    if (!type) {
      handleLastWeek();
    }
  }, [type]);

  useEffect(() => {
    if (isNull(startDate) && isNull(endDate)) {
      handleLastWeek();
    }
  }, [startDate, endDate]);

  return (
    <div className={classNames(styles.rangeFilter)}>
      <ul>
        <li
          className={classNames({
            [styles.active]: type === 'prevWeek',
          })}
        >
          <ActionButton data="Last Week" onClick={handleLastWeek} />
        </li>
        <li
          className={classNames({
            [styles.active]: type === 'month',
          })}
        >
          <ActionButton data="This Month" onClick={handleMonth} />
        </li>
        <li
          className={classNames({
            [styles.active]: type === 'range',
          })}
        >
          <RangePicker
            name="staffWorkLogsRange"
            dateStart={type === 'range' ? startDate : null}
            dateEnd={type === 'range' ? endDate : null}
            onChange={(update) => {
              setDateRange([
                ...update,
                'range',
              ]);
            }}
            label="Selected Period:"
          />
        </li>
      </ul>
    </div>
  );
});

RangeFilter.propTypes = {
  setDateRange: PropTypes.func.isRequired,
  range: PropTypes.array.isRequired,
};
