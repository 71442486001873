import update from 'immutability-helper';
import { createReducer } from 'redux-create-reducer';

import { staffWorkLogsReportTypes } from './actions';
import { ENTITY_NAME } from './constants';

const initialState = {
  errors: {},
  clients: [],
  isClientsFetching: false,
  data: {},
  isFetching: false,
  entityName: ENTITY_NAME,
  filters: {
    search: '',
    clientCategories: [],
    internalCategories: [],
  },
};

export default createReducer(initialState, {
  [staffWorkLogsReportTypes.GET_CLIENTS](state) {
    return update(state, {
      isClientsFetching: {
        $set: true,
      },
    });
  },

  [staffWorkLogsReportTypes.GET_CLIENTS_SUCCESS](state, { payload }) {
    return update(state, {
      isClientsFetching: {
        $set: false,
      },
      clients: {
        $set: payload.data,
      },
      errors: {
        $unset: ['clientsError'],
      },
    });
  },

  [staffWorkLogsReportTypes.GET_CLIENTS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isClientsFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
      clients: {
        $set: [],
      },
    });
  },
  [staffWorkLogsReportTypes.GET_REPORT](state) {
    return update(state, {
      isFetching: {
        $set: true,
      },
    });
  },

  [staffWorkLogsReportTypes.GET_REPORT_SUCCESS](state, { payload }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      data: {
        $set: {
          ...payload.data,
          clientCategories: [
            {
              id: 0,
              name: 'N/A',
            },
            ...payload.data.clientCategories,
          ],
          internalCategories: [
            {
              id: 0,
              name: 'N/A',
            },
            ...payload.data.internalCategories,
          ],
        },
      },
      errors: {
        $unset: ['staffWorkLogsReportError'],
      },
    });
  },

  [staffWorkLogsReportTypes.GET_REPORT_FAIL](state, {
    payload: {
      error,
    },
  }) {
    return update(state, {
      isFetching: {
        $set: false,
      },
      errors: {
        $merge: error,
      },
      data: {
        $set: {},
      },
    });
  },
  [staffWorkLogsReportTypes.CHANGE_FILTER](state, {
    payload: {
      storeKey,
      selected,
    },
  }) {
    return update(state, {
      filters: {
        [storeKey]: {
          $set: selected,
        },
      },
    });
  },
  [staffWorkLogsReportTypes.CLEAR_FILTER](state) {
    return update(state, {
      filters: {
        $set: {
          search: '',
          clientCategories: [],
          internalCategories: [],
        },
      },
    });
  },
  [staffWorkLogsReportTypes.CLEAR_DATA](state) {
    return update(state, {
      data: {
        $set: {},
      },
    });
  },
});
