import { createSelector } from 'reselect';

export const selectErrors = (state) => state.staffWorkLogsReport.errors;
export const selectEntityName = (state) => state.staffWorkLogsReport.entityName;

export const selectStaffWorkFetching = (state) => state.staffWorkLogsReport.isFetching;
export const selectStaffWorkLogsData = (state) => state.staffWorkLogsReport.data;

export const selectClients = (state) => state.staffWorkLogsReport.clients;
export const selectClientsFetching = (state) => state.staffWorkLogsReport.isClientsFetching;

export const selectClientsForSelect = createSelector(
  selectClients,
  (clients) => clients.map((client) => ({
    value: client.clientId,
    label: client.clientName,
  }))
);

export const selectFilters = (state) => state.staffWorkLogsReport.filters;
