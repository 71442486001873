import { useCallback, useMemo, useState } from 'react';

import {
  sortAlphabeticalByKey, sortByLabel,
  sortByProcessed, sortByRateCards,
} from 'core/configurations/utils';

export const useSortBy = (data, initialSortBy, config) => {
  const [sortBy, setSortBy] = useState(initialSortBy);
  const { rateCards, billingAgentIds, notificationTypes, emailTemplates } = config;

  const labels = {
    billingAgent: billingAgentIds,
    notification: notificationTypes,
    emailTemplateId: emailTemplates,
  };

  const records = useMemo(() => {
    if (!data.length) return [];

    if (labels[sortBy.accessor]) {
      return sortByLabel(data, sortBy.accessor, sortBy.direction, labels[sortBy.accessor]);
    }

    if (sortBy.accessor === 'processed') {
      return sortByProcessed(data, sortBy.accessor, sortBy.direction);
    }

    if (sortBy.accessor === 'rateCard' && rateCards) {
      return sortByRateCards(data, sortBy.accessor, sortBy.direction, rateCards);
    }

    return sortAlphabeticalByKey(data, sortBy.accessor, sortBy.direction);
  }, [sortBy, data]);

  const onSortHandler = useCallback((sort) => {
    setSortBy(sort);
  }, []);

  return {
    records,
    sortBy,
    onSortHandler,
  };
};
