import React, { useEffect, useState, Fragment } from 'react';

import PropTypes from 'prop-types';

import Controls, { ExpandSection } from 'components/details-controls';
import DetailsForm from 'components/details-form';
import DetailsTable from 'components/details-table';
import NavigationTabs from 'components/details-tabs';
import DocumentsControls from 'components/documents-controls';
import ToastContainer from 'components/toast';
import Wrapper from 'elements/wrapper';
import { get, isEmpty } from 'lodash';
import { withRouter } from 'react-router';

import { updateState } from 'utils/helpers/history';
import { autoScrollToTop } from 'utils/react';

import { onSubmitUploadedFile } from './utils';

const tabIndexToPath = ['details', 'billing-info', 'documents', 'task-orders', 'customers'];
const pathToTabIndex = {
  details: 0,
  'billing-info': 1,
  documents: 2,
  'task-orders': 3,
  customers: 4,
};

const ClientDetails = ({
  location,
  tab,
  push,
  clientId,
  uploadFile,
  entityName,
  orderRules,
  changeOrder,
  isNewClient,
  createClient,
  clientDetails,
  isFileUploaded,
  toggleFavorite,
  isFavoredClient,
  isFetchingFiles,
  getClientDetails,
  generateDocument,
  uploadingProgress,
  isFetchingDetails,
  changeCurrentModal,
  updateClientDetails,
  numberOfClientsFiles,
  selectTaskOrderToCopy,
  hasPermissionDocumentUpload,
  isClientDetailsFormSubmitted,
  hasPermissionClientCardHeader,
  hasPermissionDocumentGenerate,
  setMsaDate,
  msaDate,
  clearErrors,
  errors,
}) => {
  autoScrollToTop(location);
  const initialTab = get(pathToTabIndex, tab, 0);
  const [indexOfActiveTab, setIndexOfActiveTab] = useState(initialTab);
  const [currentStep, updateCurrentStep] = useState(0);
  const [clientFields, setClientFields] = useState({});

  useEffect(() => {
    getClientDetails({ clientId, withReset: true });
  }, []);

  useEffect(() => () => {
    if (clientId) {
      clearErrors();
    }
  }, [clientId]);

  const onSubmit = ({ fields, initialValues }) => {
    const updatedFields = Object.fromEntries(Object.entries(fields).map(([key, value]) => typeof (value) === 'string' ? [key, value.trim()] : [key, value]));
    if (!isNewClient) {
      return updateClientDetails({ clientId, fields: updatedFields, initialValues });
    }

    if (indexOfActiveTab) {
      const unitedFields = {
        ...clientFields,
        ...updatedFields,
      };

      return createClient({ fields: unitedFields, initialValues });
    }

    setClientFields({ ...clientFields, ...updatedFields });

    updateState({ tabIndex: 1 }, tabIndexToPath[1]);
    setIndexOfActiveTab(1);

    return updateCurrentStep(1);
  };

  const setTabIndex = (index) => {
    if (!isFetchingDetails) {
      updateState({ tabIndex: index }, tabIndexToPath[index]);
      setIndexOfActiveTab(index);

      if (isNewClient) {
        updateCurrentStep(index);
      }
    }
  };

  const { isSignedMsaUploaded, inArrears, isActive, tabStylesTemplates, actionTitle, name, status, tabHeadings, tabData, pocMsaName, pocMsaEmail } = clientDetails;

  const onGenerate = ({ title, date, generatedMsa }) => generateDocument({
    clientId,
    msaNumber: generatedMsa,
    title,
    effectiveDate: date,
  });

  const onSubmitFile = ({ ...restFileData }) => onSubmitUploadedFile({
    ...restFileData,
    clientId,
    entityName,
    onSubmit: uploadFile,
  });

  const buttonTitle = (() => {
    if (isNewClient) {
      return !indexOfActiveTab ? 'Continue' : 'Create client';
    }

    return 'Save changes';
  })();

  const unitActions = {
    push,
    selectTaskOrderToCopy,
    changeCurrentModal: ({ currentModal, ...rest }) => changeCurrentModal({ currentModal, params: { fileType: 'MSA', pocMsaName, pocMsaEmail, entityName, ...rest } }),
    openModal: ({ currentModal, ...args }) => changeCurrentModal({ currentModal, params: { ...args } }),
    setMsaDate: (date) => setMsaDate(date),
  };

  return (
    <>
      <ToastContainer containerId={entityName} isHidden={isEmpty(errors)} />
      <Controls
        toggleFavorite={() => toggleFavorite({ id: clientId })}
        isCreatMode={isNewClient}
        isFavored={isFavoredClient}
        isFetchingDetails={isFetchingDetails}
        controlsHeading={isNewClient ? 'Create Client' : name}
        goBackLinkPath="/clients"
        goBackLinkText="back to clients list"
      >
        <ExpandSection
          hasWarning={isActive && !isSignedMsaUploaded}
          dismissed={!isActive}
          title={status || 'Not yet active'}
          actionTitle={actionTitle || 'Deactivate'}
          isButtonDisabled={!hasPermissionClientCardHeader}
          onClick={() => updateClientDetails({
            clientId,
            fields: {
              isActive: !isActive,
            },
          })}
        >
          {
            isSignedMsaUploaded ? (
              <p className="client-details-controls__expand-section-title">
                {`This client is ${isActive ? '' : 'in'}active now.`}
              </p>
            ) : (
              <p className="client-details-controls__expand-section-title">
                The system will still need a signed MSA <br />
                in order to activate the client.
              </p>
            )
          }

          <p className="client-details-controls__expand-section-title">
            {`Would you like to ${isActive ? 'de' : ''}activate his record?`}
          </p>

        </ExpandSection>

        <ExpandSection
          hasWarning={inArrears}
          dismissed={!inArrears}
          title={`${inArrears ? 'In' : 'No'} arrears`}
          inArrears={inArrears}
          isButtonDisabled={!hasPermissionClientCardHeader}
          actionTitle={`${!inArrears ? 'In' : 'No'} arrears`}
          onClick={() => updateClientDetails({
            clientId,
            fields: {
              inArrears: !inArrears,
              isPlatinumRates: false,
            },
          })}
        >
          <p className="client-details-controls__expand-section-title">
            {`This client has ${inArrears ? '' : 'no'} arrears.`}
          </p>

          <p className="client-details-controls__expand-section-title">
            {`Would you like to set '${inArrears ? 'no' : 'in'} arrears' status?`}
          </p>

        </ExpandSection>

      </Controls>

      <NavigationTabs
        isCreatMode={isNewClient}
        currentStep={currentStep}
        indexOfActiveTab={indexOfActiveTab}
        setIndexOfActiveTab={setTabIndex}
        tabHeadings={tabHeadings}
      />
      {
        tabData.map((body, index) => {
          const isVisible = index === indexOfActiveTab;
          const tabStylesTemplate = tabStylesTemplates[index];
          return (
            <Wrapper
              key={index} // eslint-disable-line react/no-array-index-key
              isGrow
              isVisible={isVisible}
              cssRules={tabStylesTemplate.wrapperCssRules}
            >
              {
                body.map(({ type, withUploading, ...rest }, bodyItems) => (
                  <Fragment
                    key={bodyItems} // eslint-disable-line react/no-array-index-key
                  >
                    {
                      type === 'form' && (
                        <DetailsForm
                          buttonTitle={buttonTitle}
                          onSubmit={onSubmit}
                          isVisible={isVisible}
                          additionalActions={unitActions}
                          isFormSubmitted={isClientDetailsFormSubmitted}
                          isFetchingDetails={isFetchingDetails}
                          withErrorBox
                          {
                            ...rest
                          }
                        />
                      )
                    }

                    {
                      type === 'table' && (
                        <DetailsTable
                          isActive={isActive}
                          clientId={clientId}
                          unitActions={unitActions}
                          orderRules={orderRules}
                          changeOrder={changeOrder}
                          entityName={entityName}
                          isFetchingDetails={isFetchingDetails}
                          {
                            ...rest
                          }
                        />
                      )
                    }

                    {
                      withUploading && (
                        <DocumentsControls
                          withDocumentGenerate={hasPermissionDocumentGenerate}
                          withDocumentUpload={hasPermissionDocumentUpload}
                          keyPrefix={numberOfClientsFiles}
                          isFetchingFiles={isFetchingFiles}
                          onGenerate={onGenerate}
                          onSubmitUploadedFile={onSubmitFile}
                          isFileUploaded={isFileUploaded}
                          uploadingProgress={uploadingProgress}
                          unitActions={unitActions}
                          msaDate={msaDate}
                          {
                            ...rest
                          }
                        />
                      )
                    }
                  </Fragment>
                ))
              }
            </Wrapper>
          );
        })
      }
    </>
  );
};

ClientDetails.propTypes = {
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  isFetchingFiles: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  numberOfClientsFiles: PropTypes.number,
  generateDocument: PropTypes.func.isRequired,
  selectTaskOrderToCopy: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
  uploadingProgress: PropTypes.number.isRequired,
  uploadFile: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  tab: PropTypes.string,
  orderRules: PropTypes.shape({}).isRequired,
  changeOrder: PropTypes.func.isRequired,
  isClientDetailsFormSubmitted: PropTypes.bool.isRequired,
  createClient: PropTypes.func.isRequired,
  isFavoredClient: PropTypes.bool.isRequired,
  isNewClient: PropTypes.bool,
  toggleFavorite: PropTypes.func.isRequired,
  getClientDetails: PropTypes.func.isRequired,
  setMsaDate: PropTypes.func.isRequired,
  clientId: PropTypes.string,
  clientDetails: PropTypes.shape({
    isSignedMsaUploaded: PropTypes.bool,
    isActive: PropTypes.bool,
    inArrears: PropTypes.bool,
    actionTitle: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    tabHeadings: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      labels: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
      ])),
    })),
  }).isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  updateClientDetails: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  hasPermissionClientCardHeader: PropTypes.bool,
  hasPermissionDocumentGenerate: PropTypes.bool,
  hasPermissionDocumentUpload: PropTypes.bool,
  msaDate: PropTypes.any,
};

ClientDetails.defaultProps = {
  tab: 'details',
  isNewClient: false,
  clientId: '',
  hasPermissionClientCardHeader: false,
  hasPermissionDocumentGenerate: false,
  hasPermissionDocumentUpload: false,
  numberOfClientsFiles: null,
  msaDate: null,
};

export default withRouter(ClientDetails);
